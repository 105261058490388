<template>
  <v-container>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

          <v-form
            name="DocumentForm"
            autocomplete="off">
            <v-row no-gutters>
                <h2>
                  <span v-if="this.$store.state.editItem  === 0">
                    {{langC.CreateNew}} {{ documentNameLoc }}
                  </span>
                  <span v-if="this.$store.state.editItem === 1">
                    {{langC.Edit}} {{ documentNameLoc }} {{mainledger.BookID ? parseInt(mainledger.BookID) : 0}}
                  </span>                  
                </h2>
            </v-row>
            <br>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  {{lang.CompanyName}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.CompanyNameDatabase}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  {{lang.BusinessYear}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"  class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.BusinessYear}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                {{lang.User}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"  class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.First}} {{mainledger.Last}}, {{mainledger.email}}, {{mainledger.UserId}}</span>
              </v-col>
              <v-col cols="12" xs="6" sm="6" md="3" lg="1" xl="1" class="py-0 px-2">
                 <v-btn text color="red" class="pt-3" @click="deletePS()">{{langC.Delete}}</v-btn>
              </v-col>
            </v-row>

            <v-divider class="mt-3 mb-2"></v-divider>
            <br/>

            <v-row no-gutters v-if="show === 1">
              <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="px-2 py-2 headline" >
                Broj URE:
              </v-col>
              
              <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2">
                <v-text-field
                  class="headline"
                  dense
                  label="Broj URE"
                  v-model.number="mainledger.BookID"
                  type="number"
                  ></v-text-field>
              </v-col>
               <v-col cols="12" xs="6" sm="6" md="6" lg="1" xl="1" class="mt-n4">
                    <v-checkbox
                      class="shrink mr-0 mt-0"
                      label="INO"
                      v-model="mainledger.FreeB1"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="6" lg="3" xl="3">
                    <v-text-field 
                      label="Opis"
                      v-model="mainledger.MLFree5"
                    ></v-text-field>
                </v-col>
            </v-row>
            

            <v-row no-gutters v-if="show === 2">
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="py-4 px-2 headline">
                    Broj IRE:
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="pr-2">
                  <v-text-field class="headline"
                      label="Broj IRE"
                      v-model="mainledger.InvoiceNumber"
                      type="number"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="6" lg="1" xl="1" class="mt-n4">
                    <v-checkbox
                      label="INO"
                      v-model="mainledger.MLBool1"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="6" lg="3" xl="3">
                    <v-text-field 
                      label="Opis"
                      v-model="mainledger.MLFree5"
                    ></v-text-field>
                </v-col>
            </v-row> 

             <v-row no-gutters v-if="currYear <=2022">

                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2 headline">
                    Iznos računa:
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field
                      class="headline pr-4"
                      label="Primarni iznos"
                      v-model.number="mainledger.InvoiceAmount"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-2">
                    <v-select label="Primarna valuta" :items="currnecyType" v-model="mainledger.HomeCurrency" value="mainledger.HomeCurrency"></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pt-2 ml-3 mr-2">
                  <v-text-field
                      dense
                      label="Sekun.iznos"
                      v-model.number="mainledger.InvoiceForeginAmount"
                      type="number"
                  ></v-text-field> 
                </v-col>

            </v-row> 
            <v-row no-gutters v-if="currYear > 2022">

                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2 headline">
                    Iznos računa €:
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field
                      class="headline pr-4"
                      label="Iznos računa €"
                      v-model.number="mainledger.InvoiceAmount"
                      type="number"
                      :disabled="backCalc"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="2" sm="2" md="1" lg="1" xl="1" class="pt-2 ml-3 mr-2">
                  <v-icon
                    small
                    class="mr-2"
                    @click="calcEUR()"
                  >
                    mdi-pencil
                  </v-icon>
                </v-col>
                <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2" class="pt-2 ml-3 mr-2">
                  <v-text-field
                      dense
                      label="Iznos Kuna"
                      v-model.number="mainledger.InvoiceForeginAmount"
                      type="number"
                      :disabled="!backCalc"
                  ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="6" lg="2" xl="2" class="ml-2">
                  <v-checkbox
                    :label="lang.PayedVAT"
                    v-model="mainledger.PayedVAT"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="6" lg="2" xl="2" class="ml-2">
                  <v-checkbox
                    :label="lang.partnerNotInVAT"
                    v-model="mainledger.partnerNotInVAT"
                  ></v-checkbox>
                </v-col>

            </v-row> 

          <v-row no-gutters class="pb-3">
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                 
              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2" >
                <span class="grey--text">{{lang.BookingDate}}  {{localDate(mainledger.BookingDate)}} </span>
             </v-col>

             <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                {{lang.InvoiceDate}}:<input type="date" v-model="mainledger.InvoiceDate" /> 
             </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                {{lang.InvoicePaymentDeadlineDate}}:<input type="date" v-model="mainledger.InvoicePaymentDeadlineDate" /> 
             </v-col>

           </v-row>

           <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="py-4 px-2 headline">
                    {{langC.Partner}}:
                </v-col>


                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class=" px-0 pr-2">
                  <v-autocomplete
                    :items="partners"
                    v-model="selectPa"
                    :label="langC.SelectPartner"
                    item-text="partnerName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field
                    class="pt-0"
                      label="OIB *"
                      v-model="mainledger.partnerVATID"
                      required
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <p class="text-left">
                      <v-btn text color="primary" class="pt-3" @click="findByVATID()">{{langC.checkVATID}}</v-btn>
                    </p>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <p class="text-right">
                     <v-btn text color="primary" class="pt-3" @click="addeditPartner()">{{lang.PartnerSave}}</v-btn>
                  </p>
                </v-col>

              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                    <v-text-field class="pr-2"
                      label="Naziv partnera"
                      v-model="mainledger.partnerName"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field class="pr-2"
                      label="IBAN"
                      v-model="mainledger.partnerBankIBAN"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field class="pr-2"
                    :label="lang.Partneremail"
                    v-model="mainledger.partneremail"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                    <v-text-field
                      label="Ulica"
                      v-model="mainledger.partnerStreet"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                    <v-text-field
                      label="Br"
                      v-model="mainledger.partnerBuildingNameNumber"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                    <v-text-field
                      label="Mjesto"
                      v-model="mainledger.partnerCity"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <v-text-field
                      label="Pošta"
                      v-model="mainledger.partnerPostcode"
                    ></v-text-field> 
                </v-col>
              </v-row>


          <div v-if="showFull">
            <v-divider class="mb-4"></v-divider>
            <div v-if="showVAT">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="3"  lg="3" xl="3" class="px-2" >
                
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2" class="pr-3">
                <small>PDV iznos</small>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                <small>%PDV može se odbbiti</small>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                <small>Izn. Može se odbiti</small>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                <small>Izn.NE može se odbiti</small>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1">
                <small>Osnovica</small>
              </v-col>
            </v-row>
            
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="3"  lg="3" xl="3" class="px-2" >
                PDV 25%:
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT1Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                <v-text-field
                      dense
                      v-model.number="mainledger.VAT1PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT1CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT1CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT1Base"
                      type="number"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="3" xl="3" class="px-2" >
                PDV 13%:
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2Base"
                      type="number"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="3" xl="3" class="px-2" >
                PDV 5%:
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3Base"
                      type="number"
                  ></v-text-field>
              </v-col>
            </v-row>             

            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="3" xl="3" class="py-4 px-2" >
                Ostalo:
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="Neoporezivi iznos"
                      v-model.number="mainledger.VAT4Base"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="PDV ukupno (25+13+5)"
                      v-model.number="mainledger.AmountVAT"
                      type="number"
                      disabled
                  ></v-text-field>
 
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" class="pr-3">
 
              </v-col>
               <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" class="px-2" >
                PDV 0%:
              </v-col>
               <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="PDV 0%"
                      v-model.number="mainledger.VATZero"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="= Porezna osnovica:"
                      v-model.number="mainledger.AmountNetto"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

            </v-row>
            <v-row no-gutters>
                <v-col cols="12" xs="6" sm="6" md="6" lg="1" xl="1" class="mt-n4">
                    <v-checkbox
                      :label="lang.PaidClosed"
                      v-model="mainledger.paidClosed"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="6"  lg="2" xl="2" >
                  <input type="date" v-model="mainledger.paidDate" /> 
             </v-col>
            </v-row>
            </div>

          </div>

                       
          </v-form>
          <div class="pink" v-html="error" />

          <v-btn 
            class="primary mr-1"
             :disabled="!formIsValid"
            @click="beforeRegister" >
            PROKNJIŽI
          </v-btn>
          <span v-if="this.$store.state.editItem === 0">
            <v-btn
              class="primary mr-1"
              @click="mainledgeragain">
              OTVORI NOVI
            </v-btn>
          </span>
          <v-btn
            color="green"
            @click="mainledgerlist">
            <span v-if="this.$store.state.language == 'EN'">
            Back
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag
            </span>
          </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import MainLedgerService from '@/services/MainLedgerService'
//import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import ledgerEN from '../MainLedger/MainLedgerDescEN'
import ledgerHR from '../MainLedger/MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { defNew, defMainQuery, defMainLedger, defNewpartner } from '../MainLedger/mainledgerHelper'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'
import {parseNum, round, dateFormat} from '@/snippets/allSnippets'
import dayjs from 'dayjs'
//mport FileSaver from 'file-saver'

// import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'mainbookcomppscreate',
  props: [],
  data () {
    return {
      admin: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      msg: '',
      documentName: '',
      documentNameLoc: '',
      mainledger: { },
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectBank: {},
      selectCR: {},
      selectUs: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        MLType: 0
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      localUser: '',
      bookType: [],
      bookTypeSelected: {
      },
      bookSubType: [],
      bookSubTypeSelected: {},
      showUpload: false,
      haveItem: null,
      selectId: null,
      haveNew: false,
      show: 1,
      mainQuery3: {},
      dog: 0,
      InvoiceAmount: 0,
      savedML: null,
      mldetails: [],
      currYear: 0,
      backCalc: false,
      showFull: false,
      showVAT: false
    }
  },
  components: {

  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }

    this.doInit()
  },
  computed: {
    formIsValid () {
      return this.mainledger.email !== '' &&
        this.mainledger.First !== '' &&
        this.mainledger.Last !== '' &&
        this.mainledger.InvoiceDate !== '' &&
        this.mainledger.BookingDate !== ''
    },
    formatDate1() {
      return this.mainledger.BookingDate
        ? dateFormat(this.mainledger.BookingDate)
        : "";
    },
    formatDate2() {
      return this.mainledger.InvoiceDate
        ? dateFormat(this.mainledger.InvoiceDate)
        : "";
    },
    formatDate3() {
      return this.mainledger.InvoicePaymentDeadlineDate
        ? dateFormat(this.mainledger.InvoicePaymentDeadlineDate)
        : "";
    }
  },
  methods: {
    calcEUR() {
      this.backCalc = !this.backCalc
    },
     localDate(dateToFormat) {
      const dateOnly = dayjs(dateToFormat).format('YYYY-MM-DD')
      return dateFormat(dateOnly)
    },
    async doInit() {
      this.showFull = this.$store.state.documentSide === 3 ||  this.$store.state.documentSide === 4 ? true : false
      if (this.showFull) {
        this.showVAT = true
      }
      this.backCalc = false
      this.currYear = this.$store.state.businessYear
      if (this.$store.state.user.Admin) {
        this.admin = this.$store.state.user.Admin
      }
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.companyid) {
        this.CompanyId = this.$store.state.companyid
        this.mainQuery3.CompanyId = this.$store.state.companyid
      }
      if (this.CompanyId > 0) {
        //await checkPartners(0)
        await testLastUpdate()
        if (this.$store.state.partnerStore) {
          this.partners = this.$store.state.partnerStore
        }
        //this.partners = (await PartnerService.index()).data.partners
      }
      this.show = 1
      if (this.$store.state.documentSide === 3) {
        this.show = 1
      }
      if (this.$store.state.documentSide === 4) {
        this.show = 2
      }

      this.bookType = this.$store.state.booktype ? this.$store.state.booktype : []
      this.bookSubType = this.$store.state.booksubtype ? this.$store.state.booksubtype : []

      if (this.$store.state.editItem === 0) {
        this.mainledger = defMainLedger()
        this.mainledger = {...this.mainledger, ...defNew()}
        this.mainQuery = {...defMainQuery()}
        this.localUser = this.mainledger.First + ' ' + this.mainledger.Last + ', ' + this.mainledger.email
        this.haveNew = true
        this.bookTypeSelected  = this.$store.state.bookTypeSelected
        // const locDate1 = new Date()
        // const dayX = locDate1.getDate()
        // const monthX = (locDate1.getMonth() + 1)
        // const yearX = (locDate1.getFullYear() -1)
        this.mainledger.BookingDate = dayjs([this.$store.state.businessYear]).startOf('year').format('YYYY-MM-DD')
        this.selectPa = {}
        this.mainledger.ExRate = this.$store.state.defExRate
        let d = new Date()
        d.setFullYear(d.getFullYear() - 1)
        const b = d.toISOString().split('T')[0]
        this.mainledger.InvoiceDate = b
        //this.mainledger.InvoicePaymentDeadlineDate = dayjs().add(15, 'days').format('YYYY-MM-DD')
      }
      if (this.$store.state.editItem === 1) {
        const locDocument = await MainLedgerService.show(this.$store.state.mainledgerid)
        this.mainledger = locDocument.data.mainLedger
        this.InvoiceAmount = this.mainledger.InvoiceAmount ? parseFloat(this.mainledger.InvoiceAmount) : 0
        this.selectPa = this.mainledger.partnerVATID ? this.partners.find(partner => (partner.partnerVATID === (this.mainledger.partnerVATID))) : null
        this.bookTypeSelected = this.mainledger.BookTypeID ? this.$store.state.booktype.find(book => (parseInt(book.value) === this.mainledger.BookTypeID )) : null
        this.bookSubTypeSelected = this.mainledger.BookSubTypeID ? this.bookSubType.find(book => (parseInt(book.value) === this.mainledger.BookSubTypeID )) : null
        this.mainledger.BookID = this.mainledger.BookID ? parseInt(this.mainledger.BookID) : 0
        this.haveNew = false
        this.doParse()
      }
      
      // if (this.$store.state.booktype) {     
      //   this.bookType = this.$store.state.booktype.filter(book => {
      //         return book.side === this.$store.state.documentSide
      //       })
      //   this.bookTypeSelected = this.mainledger.BookTypeID ? this.bookType.find(book => (parseInt(book.value) === this.mainledger.BookTypeID )) : null
      // }
      this.setBookSubTypes()
      this.bookSubTypeSelected = this.mainledger.BookSubTypeID ? this.bookSubType.find(book => (parseInt(book.value) === this.mainledger.BookSubTypeID )) : null
    },
    setBookSubTypes() {
      
    },
    async findByVATID() {
      if (this.mainledger.partnerVATID) {
        this.mainQuery = {}
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.mainQuery.partnerVATID = this.mainledger.partnerVATID
        const {data} = await PartnerService.check(this.mainQuery)
        const partnerVATID = data && data.partner && data.partner.id ? data.partner.partnerVATID : null
        if (partnerVATID) {
          this.selectPa =  this.partners.find(partner => (partner.partnerVATID === (partnerVATID)))
          await this.findPartner(partnerVATID)
          this.text = 'OK!'
          this.color = 'green'
          this.snackbar = true
        }
        if (partnerVATID.lenght === 0) {
          this.text = 'Error! Partner with this VATid not exist! Partner sa tim OIBom ne postoji!'
          this.color = 'red'
          this.snackbar = true
        }
      }
    },

    async register () {
      try {
         this.localError = false
         
          const haveYear = dayjs(this.mainledger.BookingDate).year()
          const invYear = dayjs(this.mainledger.InvoiceDate).year()
          const paymYear = dayjs(this.mainledger.InvoicePaymentDeadlineDate).year()
          
          // if (haveYear >= this.$store.state.businessYear) {
          //   this.text = 'Greška!! Godina u datumu knjiženja mora biti manja od tekuće poslovne godine !!'
          //   this.color = 'red'
          //   this.snackbar = true
          //   this.timeout = 3000
          //   return
          // }
          if (invYear >= this.$store.state.businessYear) {
            this.text = 'Greška!! Godina u datumu računa mora biti manja od tekuće poslovne godine !!'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }
          if (haveYear < (this.$store.state.businessYear - 10)) {
            this.text = 'Greška!! Godina u datumu knjiženja je manja za više od 10 godina od tekuće poslovne godine !!'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }

          if (invYear < (this.$store.state.businessYear - 10) || invYear > (this.$store.state.businessYear + 10)) {
            this.text = 'Greška!! Godina u datumu računa je pogrešna !!'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }
          if (paymYear < (this.$store.state.businessYear - 10) || paymYear > (this.$store.state.businessYear + 10)) {
            this.text = 'Greška!! Godina u roku plaćanja računa je pogrešna !!'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }


         if (!this.mainledger.InvoiceAmount || this.mainledger.InvoiceAmount === 0){
          this.text = 'Greska! Iznos je 0!! Error!! Invoice Amount is zero '
          this.color = 'red'
          this.snackbar = true
          return
         }

         if (this.selectPa.partnerVATID) {
            const locPartner = (await PartnerService.show(this.selectPa.partnerVATID)).data.partner
            this.mainledger.partnerId = this.selectPa.id
            this.mainledger.partnerName = locPartner.partnerName
            this.mainledger.partnerNameShort = locPartner.partnerNameShort
            this.mainledger.partnerNameDatabase = locPartner.partnerNameDatabase
            this.mainledger.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
            this.mainledger.partnerStreet = locPartner.partnerStreet
            this.mainledger.partnerCity = locPartner.partnerCity
            this.mainledger.partnerPostcode = locPartner.partnerPostcode
            this.mainledger.partnerCountyRegion = locPartner.partnerCountyRegion
            this.mainledger.partnerState = locPartner.partnerState
            this.mainledger.partnerVATID = locPartner.partnerVATID
            this.mainledger.partneremail = locPartner.partneremail
            this.mainledger.partnerPhones = locPartner.partnerPhones
            this.mainledger.partnerBankName = locPartner.partnerBankName
            this.mainledger.partnerBankAddress = locPartner.partnerBankAddress
            this.mainledger.partnerBankIBAN = locPartner.partnerBankIBAN
            this.mainledger.partnerBankSWIFT = locPartner.partnerBankSWIFT
        }

        if (!this.mainledger.partnerName || this.mainledger.partnerName.trim().length === 0){
          this.text = 'Greska! Nema naziva partnera!! Error!! Partner name is empty!! '
          this.color = 'red'
          this.snackbar = true
          return
        }
        if (!this.mainledger.partnerVATID || this.mainledger.partnerVATID.trim().length === 0){
          this.text = 'Greska! Nema OIBa partnera!! Error!! Partner VATid is empty!! '
          this.color = 'red'
          this.snackbar = true
          return
        }

        if (this.show === 1) {
          if (this.mainledger.FreeB1 === false) {
            const partVatLeght = this.mainledger.partnerVATID.trim().length
            if (partVatLeght !== 11) {
              this.text = this.lang.PartnerErrorVAT3
              this.color = 'red'
              this.snackbar = true
              this.timeout = 3000
              return
            }
          }
        }
        if (this.show === 2) {
          if (this.mainledger.MLBool1 === false) {
            const partVatLeght = this.mainledger.partnerVATID.trim().length
            if (partVatLeght !== 11) {
              this.text = this.lang.PartnerErrorVAT3
              this.color = 'red'
              this.snackbar = true
              this.timeout = 3000
              return
            }
          }
        }
        const yearInv = this.mainledger.InvoiceDate ? this.mainledger.InvoiceDate.trim().substr(0,4) : null
        if (!yearInv) {
          this.text = 'Greška! Datum računa NIJE upisan!'
          this.color = 'red'
          this.snackbar = true
          this.timeout = 3000
          return
        }
        
        await this.checkNotNull()
        // if (!this.admin) {
        //   if (this.$store.state.editItem === 0 && this.mainledger.BookID !== 0) {   
        //     this.text = 'Greska! Dokument je vec snimljen!! '
        //     this.color = 'red'
        //     this.snackbar = true
        //     return
        //   }
        // }
        this.mainQuery = {...defMainQuery()}
        
        

        if (this.bookSubTypeSelected && this.bookSubTypeSelected.value) {
            this.mainledger.BookSubTypeID = parseInt(this.bookSubTypeSelected.value)
            this.mainledger.BookSubName = this.bookSubTypeSelected.text
        } else {
          this.mainledger.BookSubTypeID = null
          this.mainledger.BookSubName = null
        }

        if (this.$store.state.editItem === 0) {

          if (this.$store.state.bookTypeSelected && this.$store.state.bookTypeSelected.value) {

            const fullNr = '000000'
            const rightNr = (this.show === 1 ? this.mainledger.BookID.toString() : this.mainledger.InvoiceNumber.toString())
            const rightNrLen = rightNr.toString().length
            const onlyZeros = fullNr.substring(0, (6-rightNrLen))
            const newBookID = haveYear.toString() + onlyZeros + rightNr
            this.mainledger.BookID = parseInt(newBookID)

            // when enter NEW invoice check if already exist with these number
            const findWhat = {}
            findWhat.CompanyId  = this.$store.state.companyid ? this.$store.state.companyid : 1
            findWhat.BusinessYear =  this.$store.state.businessYear ? this.$store.state.businessYear : 2021
            findWhat.BookTypeID = this.$store.state.bookTypeSelected.value
            findWhat.BookID = this.mainledger.BookID ? this.mainledger.BookID : 9999999999
            const res = await MainLedgerService.find(findWhat)
            if (res.data && res.data.mainLedger && res.data.mainLedger.length > 0) {
              this.text = 'Greska! Dokument sa tim brojem već postoji u knjizi. Error! Invoice with this number already exist in books! '
              this.color = 'red'
              this.snackbar = true
              return
            }
            
            if (this.mainledger.BookID === 0) {
              this.mainledger.BookID = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
            }

            this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.mainledger.BookID 
            await MainLedgerService.post(this.mainledger)
            .then(res => {
              this.savedML = res.data.mainledger ? res.data.mainledger : {}
              if (this.$store.state.language === 'EN') {
                this.text = 'New mainledger doc has been saved.'
                this.color = 'green'
                this.snackbar = true
              }
              if (this.$store.state.language === 'HR') {
                this.text = this.$store.state.documentSide === 1 ? 'Nova ura je snimljena u bazu.' : 'Nova ira je snimljena u bazu.'
                this.color = 'green'
                this.snackbar = true
              }
            })
            .catch(err => {
              this.error = err.data
              this.text = 'Error1! Cannot save document to database. ' + err
              this.color = 'red'
              this.snackbar = true
            })
          } else {
            this.text = 'Greska! Nije odabrana vrsta knjige!!'
            this.color = 'red'
            this.snackbar = true
          }
        }

        if (this.$store.state.editItem === 1) {

          await MainLedgerService.put(this.mainledger)
        
          this.$router.push({
            name: 'mainbookcomppslist'
          })
        }

      } catch (error) {
        this.error = error.data
        this.text = 'Error2! Cannot save document to database. ' + error.data
        this.color = 'red'
        this.snackbar = true
      }
    },
    async deletePS() {
      if (confirm('Da li ste sigurni da želite obrisati stavku ??')) {

          await MainLedgerService.delete(this.mainledger.id)
        
          this.$router.push({
            name: 'mainbookcomppslist'
          })
        }
    },
    close () {
      this.show = false
    },
    calculateSecAmount () {
      this.mainledger.InvoiceForeginAmount = (this.mainledger.InvoiceAmount) * (this.mainledger.ExRate)
      // this.mainledger.VAT1Base = this.round((((this.mainledger.InvoiceAmount) / (1 + (this.mainledger.VAT1Per) / 100))),2)
      // this.mainledger.VAT1Amount = this.round(((this.mainledger.InvoiceAmount) - this.mainledger.VAT1Base),2)
      // this.mainledger.VAT1CanUse = this.round(((this.mainledger.InvoiceAmount) - this.mainledger.VAT1Base),2)
    },
    calculateFirstAmount () {
      if (round(this.mainledger.InvoiceAmount, 2) !== 0) {
        this.mainledger.InvoiceForeginAmount = this.round(((this.mainledger.InvoiceAmount) / (this.mainledger.ExRate)),2)
        //this.mainledger.InvoiceAmount = this.round((this.mainledger.InvoiceForeginAmount * this.mainledger.ExRate),2)
        this.mainledger.VAT1Base = this.round((((this.mainledger.InvoiceAmount) / (1 + (this.mainledger.VAT1Per) / 100))),2)
        this.mainledger.VAT1Amount = this.round(((this.mainledger.InvoiceAmount) - this.mainledger.VAT1Base),2)
        this.mainledger.VAT1CanUse = this.round(((this.mainledger.InvoiceAmount) - this.mainledger.VAT1Base),2)
      }
    },
    calculateFirstVAT () {
      this.mainledger.VAT1CanUse = round((this.mainledger.VAT1Amount * (this.mainledger.VAT1PerCanUse / 100)) ,2)
      this.mainledger.VAT1CanTUse = this.mainledger.VAT1Amount - this.mainledger.VAT1CanUse
      this.mainledger.VAT1Base = round(((this.mainledger.VAT1CanUse + this.mainledger.VAT1CanTUse) / ( this.mainledger.VAT1Per / 100)),2)
      this.calculateVATAmount()
    },
    calculateSecondVAT () {
      this.mainledger.VAT2CanUse = round((this.mainledger.VAT2Amount * (this.mainledger.VAT2PerCanUse / 100)), 2)
      this.mainledger.VAT2CanTUse = this.mainledger.VAT2Amount - this.mainledger.VAT2CanUse
      this.mainledger.VAT2Base = round(((this.mainledger.VAT2CanUse + this.mainledger.VAT2CanTUse) / ( this.mainledger.VAT2Per / 100)), 2)
      this.calculateVATAmount()
    },
    calculateThirdVAT () {
      this.mainledger.VAT3CanUse = round((this.mainledger.VAT3Amount * ((this.mainledger.VAT3PerCanUse) / 100)), 2)
      this.mainledger.VAT3CanTUse = this.mainledger.VAT3Amount - this.mainledger.VAT3CanUse
      this.mainledger.VAT3Base = round(((this.mainledger.VAT3CanUse + this.mainledger.VAT3CanTUse) / (this.mainledger.VAT3Per / 100)), 2)
      this.calculateVATAmount()
    },
    beforeRegister() {
      this.checkNotNull()
      //const haveDiff = round((this.mainledger.InvoiceAmount - this.mainledger.VAT1Base - this.mainledger.VAT1Amount - this.mainledger.VAT2Base - this.mainledger.VAT2Amount - this.mainledger.VAT3Base - this.mainledger.VAT3Amount - this.mainledger.VAT4Base), 2)
      // if (haveDiff !== 0) {
      //   if (confirm('Razlika kod razrade računa u iznosu = ' + haveDiff + '.Da li ste sigurni da želite proknjižiti taj račun?')) {
      //     this.register()
      //   }
      // } else {
        this.register()
      //}
    },
    calculateVATAmount () {
      // this.mainledger.VAT1Amount = this.mainledger.VAT1Amount ? (this.mainledger.VAT1Amount) : 0
      // this.mainledger.VAT2Amount = this.mainledger.VAT2Amount ? (this.mainledger.VAT2Amount) : 0
      // this.mainledger.VAT3Amount = this.mainledger.VAT3Amount ? (this.mainledger.VAT3Amount) : 0
      // this.mainledger.VAT4Base = this.mainledger.VAT4Base ? (this.mainledger.VAT4Base) : 0
      // this.mainledger.InvoiceAmount = this.mainledger.InvoiceAmount ? (this.mainledger.InvoiceAmount) : 0
      this.mainledger.AmountVAT = ((this.mainledger.VAT1Amount + this.mainledger.VAT2Amount + this.mainledger.VAT3Amount))
      this.mainledger.AmountNetto = ((this.mainledger.InvoiceAmount - this.mainledger.AmountVAT - this.mainledger.VAT4Base))
    },
    async findPartner () {
       
      if (this.selectPa && this.selectPa.partnerVATID) {
        const locPartner = (await PartnerService.show(this.selectPa.partnerVATID)).data.partner
        this.mainledger.partnerId = this.selectPa.id
        this.mainledger.partnerName = locPartner.partnerName
        this.mainledger.partnerNameShort = locPartner.partnerNameShort
        this.mainledger.partnerNameDatabase = locPartner.partnerNameDatabase
        this.mainledger.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
        this.mainledger.partnerStreet = locPartner.partnerStreet
        this.mainledger.partnerCity = locPartner.partnerCity
        this.mainledger.partnerPostcode = locPartner.partnerPostcode
        this.mainledger.partnerCountyRegion = locPartner.partnerCountyRegion
        this.mainledger.partnerState = locPartner.partnerState
        this.mainledger.partnerVATID = locPartner.partnerVATID
        this.mainledger.partneremail = locPartner.partneremail
        this.mainledger.partnerPhones = locPartner.partnerPhones
        this.mainledger.partnerBankName = locPartner.partnerBankName
        this.mainledger.partnerBankAddress = locPartner.partnerBankAddress
        this.mainledger.partnerBankIBAN = locPartner.partnerBankIBAN
        this.mainledger.partnerBankSWIFT = locPartner.partnerBankSWIFT
      }
    },
    checkNotNull() {
      this.mainledger.InvoiceAmount = this.mainledger.InvoiceAmount ? (this.mainledger.InvoiceAmount) : 0
      this.mainledger.InvoiceForeginAmount = this.mainledger.InvoiceForeginAmount ? (this.mainledger.InvoiceForeginAmount) : 0
      //this.mainledger.VAT1Per = this.mainledger.VAT1Per ? (this.mainledger.VAT1Per) : 25
      this.mainledger.VAT1Base = this.mainledger.VAT1Base ? (this.mainledger.VAT1Base) : 0
      this.mainledger.VAT1Amount = this.mainledger.VAT1Amount ? (this.mainledger.VAT1Amount) : 0
      this.mainledger.VAT1PerCanUse = this.mainledger.VAT1PerCanUse ? (this.mainledger.VAT1PerCanUse) : 100
      this.mainledger.VAT1CanUse = this.mainledger.VAT1CanUse ? (this.mainledger.VAT1CanUse) : 0
      this.mainledger.VAT1CanTUse = this.mainledger.VAT1CanTUse ? (this.mainledger.VAT1CanTUse) : 0
      //this.mainledger.VAT2Per = this.mainledger.VAT2Per ? (this.mainledger.VAT2Per) : 13
      this.mainledger.VAT2Base = this.mainledger.VAT2Base ? (this.mainledger.VAT2Base) : 0
      this.mainledger.VAT2Amount = this.mainledger.VAT2Amount ? (this.mainledger.VAT2Amount) : 0
      this.mainledger.VAT2PerCanUse = this.mainledger.VAT2PerCanUse ? (this.mainledger.VAT2PerCanUse) : 100
      this.mainledger.VAT2CanUse = this.mainledger.VAT2CanUse ? (this.mainledger.VAT2CanUse) : 0
      this.mainledger.VAT2CanTUse = this.mainledger.VAT2CanTUse ? (this.mainledger.VAT2CanTUse) : 0
      //this.mainledger.VAT3Per = this.mainledger.VAT3Per ? (this.mainledger.VAT3Per) : 5
      this.mainledger.VAT3Base = this.mainledger.VAT3Base ? (this.mainledger.VAT3Base) : 0
      this.mainledger.VAT3Amount = this.mainledger.VAT3Amount ? (this.mainledger.VAT3Amount) : 0
      this.mainledger.VAT3PerCanUse = this.mainledger.VAT3PerCanUse ? (this.mainledger.VAT3PerCanUse) : 100
      this.mainledger.VAT3CanUse = this.mainledger.VAT3CanUse ? (this.mainledger.VAT3CanUse) : 0
      this.mainledger.VAT3CanTUse = this.mainledger.VAT3CanTUse ? (this.mainledger.VAT3CanTUse) : 0
      this.mainledger.VAT4Per = this.mainledger.VAT4Per ? (this.mainledger.VAT4Per) : 0
      this.mainledger.VAT4Base = this.mainledger.VAT4Base ? (this.mainledger.VAT4Base) : 0
      this.mainledger.VAT4Amount = this.mainledger.VAT4Amount ? (this.mainledger.VAT4Amount) : 0
      this.mainledger.VAT4PerCanUse = this.mainledger.VAT4PerCanUse ? (this.mainledger.VAT4PerCanUse) : 0
      this.mainledger.VAT4CanUse = this.mainledger.VAT4CanUse ? (this.mainledger.VAT4CanUse) : 0
      this.mainledger.VAT4CanTUse = this.mainledger.VAT4CanTUse ? (this.mainledger.VAT4CanTUse) : 0
      this.mainledger.VAT5Per = this.mainledger.VAT5Per ? (this.mainledger.VAT5Per) : 0
      this.mainledger.VAT5Base = this.mainledger.VAT5Base? (this.mainledger.VAT5Base) : 0
      this.mainledger.VAT5Amount = this.mainledger.VAT5Amount ? (this.mainledger.VAT5Amount) : 0
      this.mainledger.VAT5PerCanUse = this.mainledger.VAT5PerCanUse ? (this.mainledger.VAT5PerCanUse) : 0
      this.mainledger.VAT5CanUse = this.mainledger.VAT5CanUse  ? (this.mainledger.VAT5CanUse) : 0
      this.mainledger.VAT5CanTUse = this.mainledger.VAT5CanTUse ? (this.mainledger.VAT5CanTUse) : 0

      this.mainledger.AmountNetto = this.mainledger.AmountNetto  ? (this.mainledger.AmountNetto) : 0
      this.mainledger.AmountVAT = this.mainledger.AmountVAT ? (this.mainledger.AmountVAT) : 0
      this.mainledger.AmountBrutto = this.mainledger.AmountBrutto ? (this.mainledger.AmountBrutto) : 0
      this.mainledger.AmountPayInAdvance = this.mainledger.AmountPayInAdvance ? (this.mainledger.AmountPayInAdvance) : 0
      this.mainledger.AmountToPay = this.mainledger.AmountToPay ? (this.mainledger.AmountToPay) : 0
      this.mainledger.AmountNettoForeign = this.mainledger.AmountNettoForeign? (this.mainledger.AmountNettoForeign) : 0
      this.mainledger.AmountVATForeign = this.mainledger.AmountVATForeign ? (this.mainledger.AmountVATForeign) : 0
      this.mainledger.AmountBruttoForeign = this.mainledger.AmountBruttoForeign ? (this.mainledger.AmountBruttoForeign) : 0
      this.mainledger.AmountPayInAdvanceForeign = this.mainledger.AmountPayInAdvanceForeign ? (this.mainledger.AmountPayInAdvanceForeign): 0
      this.mainledger.AmountToPayForeign = this.mainledger.AmountToPayForeign ? (this.mainledger.AmountToPayForeign) : 0
      this.mainledger.Debit = this.mainledger.Debit? (this.mainledger.Debit) : 0,
      this.mainledger.Credit = this.mainledger.Credit ? (this.mainledger.Credit) : 0
      this.mainledger.DebitForegin = this.mainledger.DebitForegin ? (this.mainledger.DebitForegin) : 0
      this.mainledger.CreditForegin = this.mainledger.CreditForegin ? (this.mainledger.CreditForegin) : 0
      this.mainledger.DebitTotal = this.mainledger.DebitTotal ? (this.mainledger.DebitTotal) : 0
      this.mainledger.CreditTotal = this.mainledger.CreditTotal ? (this.mainledger.CreditTotal): 0
      this.mainledger.DebitForeginTotal = this.mainledger.DebitForeginTotal ? (this.mainledger.DebitForeginTotal) : 0
      this.mainledger.CreditForeginTotal = this.mainledger.CreditForeginTotal? (this.mainledger.CreditForeginTotal) : 0

      this.mainledger.WareHouseId = this.mainledger.WareHouseId ? this.mainledger.WareHouseId : 0
      this.mainledger.WareHouseEntry = this.mainledger.WareHouseEntry ? this.mainledger.WareHouseEntry : 0
      this.mainledger.WareHouseEntryAmount = this.mainledger.WareHouseEntryAmount? this.mainledger.WareHouseEntryAmount : 0
    },
    doParse() {
      this.mainledger.InvoiceAmount = this.mainledger.InvoiceAmount ? parseNum(this.mainledger.InvoiceAmount) : 0
      this.mainledger.InvoiceForeginAmount = this.mainledger.InvoiceForeginAmount ? parseNum(this.mainledger.InvoiceForeginAmount) : 0
      //this.mainledger.VAT1Per = this.mainledger.VAT1Per ? parseNum(this.mainledger.VAT1Per) : 25
      this.mainledger.VAT1Base = this.mainledger.VAT1Base ? parseNum(this.mainledger.VAT1Base) : 0
      this.mainledger.VAT1Amount = this.mainledger.VAT1Amount ? parseNum(this.mainledger.VAT1Amount) : 0
      this.mainledger.VAT1PerCanUse = this.mainledger.VAT1PerCanUse ? parseNum(this.mainledger.VAT1PerCanUse) : 100
      this.mainledger.VAT1CanUse = this.mainledger.VAT1CanUse ? parseNum(this.mainledger.VAT1CanUse) : 0
      this.mainledger.VAT1CanTUse = this.mainledger.VAT1CanTUse ? parseNum(this.mainledger.VAT1CanTUse) : 0
      //this.mainledger.VAT2Per = this.mainledger.VAT2Per ? parseNum(this.mainledger.VAT2Per) : 13
      this.mainledger.VAT2Base = this.mainledger.VAT2Base ? parseNum(this.mainledger.VAT2Base) : 0
      this.mainledger.VAT2Amount = this.mainledger.VAT2Amount ? parseNum(this.mainledger.VAT2Amount) : 0
      this.mainledger.VAT2PerCanUse = this.mainledger.VAT2PerCanUse ? parseNum(this.mainledger.VAT2PerCanUse) : 100
      this.mainledger.VAT2CanUse = this.mainledger.VAT2CanUse ? parseNum(this.mainledger.VAT2CanUse) : 0
      this.mainledger.VAT2CanTUse = this.mainledger.VAT2CanTUse ? parseNum(this.mainledger.VAT2CanTUse) : 0
      //this.mainledger.VAT3Per = this.mainledger.VAT3Per ? parseNum(this.mainledger.VAT3Per) : 5

      this.mainledger.VAT3Base = this.mainledger.VAT3Base ? parseNum(this.mainledger.VAT3Base) : 0
      this.mainledger.VAT3Amount = this.mainledger.VAT3Amount ? parseNum(this.mainledger.VAT3Amount) : 0
      this.mainledger.VAT3PerCanUse = this.mainledger.VAT3PerCanUse ? parseNum(this.mainledger.VAT3PerCanUse) : 100
      this.mainledger.VAT3CanUse = this.mainledger.VAT3CanUse ? parseNum(this.mainledger.VAT3CanUse) : 0
      this.mainledger.VAT3CanTUse = this.mainledger.VAT3CanTUse ? parseNum(this.mainledger.VAT3CanTUse) : 0
      this.mainledger.VAT4Per = this.mainledger.VAT4Per ? parseNum(this.mainledger.VAT4Per) : 0
      this.mainledger.VAT4Base = this.mainledger.VAT4Base ? parseNum(this.mainledger.VAT4Base) : 0
      this.mainledger.VAT4Amount = this.mainledger.VAT4Amount ? parseNum(this.mainledger.VAT4Amount) : 0
      this.mainledger.VAT4PerCanUse = this.mainledger.VAT4PerCanUse ? parseNum(this.mainledger.VAT4PerCanUse) : 0
      this.mainledger.VAT4CanUse = this.mainledger.VAT4CanUse ? parseNum(this.mainledger.VAT4CanUse) : 0
      this.mainledger.VAT4CanTUse = this.mainledger.VAT4CanTUse ? parseNum(this.mainledger.VAT4CanTUse) : 0
      this.mainledger.VAT5Per = this.mainledger.VAT5Per ? parseNum(this.mainledger.VAT5Per) : 0
      this.mainledger.VAT5Base = this.mainledger.VAT5Base ? parseNum(this.mainledger.VAT5Base) : 0
      this.mainledger.VAT5Amount = this.mainledger.VAT5Amount ? parseNum(this.mainledger.VAT5Amount) : 0
      this.mainledger.VAT5PerCanUse = this.mainledger.VAT5PerCanUse ? parseNum(this.mainledger.VAT5PerCanUse) : 0
      this.mainledger.VAT5CanUse = this.mainledger.VAT5CanUse ? parseNum(this.mainledger.VAT5CanUse) : 0
      this.mainledger.VAT5CanTUse = this.mainledger.VAT5CanTUse ? parseNum(this.mainledger.VAT5CanTUse) : 0
 
      this.mainledger.AmountNetto = this.mainledger.AmountNetto ? parseNum(this.mainledger.AmountNetto) : 0
      this.mainledger.AmountVAT = this.mainledger.AmountVAT ? parseNum(this.mainledger.AmountVAT) : 0
      this.mainledger.AmountBrutto = this.mainledger.AmountBrutto ? parseNum(this.mainledger.AmountBrutto) : 0
      this.mainledger.AmountPayInAdvance = this.mainledger.AmountPayInAdvance ? parseNum(this.mainledger.AmountPayInAdvance) : 0
      this.mainledger.AmountToPay = this.mainledger.AmountToPay ? parseNum(this.mainledger.AmountToPay) : 0
      this.mainledger.AmountNettoForeign = this.mainledger.AmountNettoForeign ? parseNum(this.mainledger.AmountNettoForeign) : 0
      this.mainledger.AmountVATForeign = this.mainledger.AmountVATForeign ? parseNum(this.mainledger.AmountVATForeign) : 0
      this.mainledger.AmountBruttoForeign = this.mainledger.AmountBruttoForeign ? parseNum(this.mainledger.AmountBruttoForeign) : 0
      this.mainledger.AmountPayInAdvanceForeign = this.mainledger.AmountPayInAdvanceForeign ? parseNum(this.mainledger.AmountPayInAdvanceForeign): 0
      this.mainledger.AmountToPayForeign = this.mainledger.AmountToPayForeign ? parseNum(this.mainledger.AmountToPayForeign) : 0
      this.mainledger.Debit = this.mainledger.Debit ? parseNum(this.mainledger.Debit) : 0
      this.mainledger.Credit = this.mainledger.Credit ? parseNum(this.mainledger.Credit) : 0
      this.mainledger.DebitForegin = this.mainledger.DebitForegin ? parseNum(this.mainledger.DebitForegin) : 0
      this.mainledger.CreditForegin = this.mainledger.CreditForegin ? parseNum(this.mainledger.CreditForegin) : 0
      this.mainledger.DebitTotal = this.mainledger.DebitTotal ? parseNum(this.mainledger.DebitTotal) : 0
      this.mainledger.CreditTotal = this.mainledger.CreditTotal ? parseNum(this.mainledger.CreditTotal): 0
      this.mainledger.DebitForeginTotal = this.mainledger.DebitForeginTotal ? parseNum(this.mainledger.DebitForeginTotal) : 0
      this.mainledger.CreditForeginTotal = this.mainledger.CreditForeginTotal ? parseNum(this.mainledger.CreditForeginTotal) : 0
      this.mainledger.WareHouseId = this.mainledger.WareHouseId ? this.mainledger.WareHouseId : 0
      this.mainledger.WareHouseEntry = this.mainledger.WareHouseEntry ? this.mainledger.WareHouseEntry : 0
      this.mainledger.WareHouseEntryAmount = this.mainledger.WareHouseEntryAmount ? this.mainledger.WareHouseEntryAmount : 0
      this.mainledger.Goods = this.mainledger.Goods ? parseNum(this.mainledger.Goods) : 0
      this.mainledger.Services = this.mainledger.Services ? parseNum(this.mainledger.Services) : 0
      this.mainledger.GoodsEU = this.mainledger.GoodsEU ? parseNum(this.mainledger.GoodsEU) : 0
      this.mainledger.ServicesEU = this.mainledger.ServicesEU ? parseNum(this.mainledger.ServicesEU) : 0
    },
    async mainledgerlist () {
      try {
        await this.$router.push({
          name: 'mainbookcomppslist'
        })
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },

    async mainledgeragain () {
      try {
        // this.$store.dispatch('setpathAgain', 'mainbookcomppscreate')
        // await this.$router.push({
        //   name: 'mainbookcomppscreate'
        // })
        // Object.assign(this.$data, this.$options.data.call(this))
        // location.reload()
        // this.$forceUpdate()
        this.doInit()
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    clearPartner() {
      this.selectPa = null
      this.mainledger.partnerId = null
      this.mainledger.partnerName = null
      this.mainledger.partnerNameShort = null
      this.mainledger.partnerNameDatabase = null
      this.mainledger.partnerBuildingNameNumber = null
      this.mainledger.partnerStreet = null
      this.mainledger.partnerCity = null
      this.mainledger.partnerPostcode = null
      this.mainledger.partnerCountyRegion = null
      this.mainledger.partnerState = null
      this.mainledger.partnerVATID = null
      this.mainledger.partneremail = null
      this.mainledger.partnerPhones = null
      this.mainledger.partnerBankName = null
      this.mainledger.partnerBankAddress = null
      this.mainledger.partnerBankIBAN = null
      this.mainledger.partnerBankSWIFT = null
      this.mainledger.partnerContactTime = null
      this.mainledger.partnerSecretaryTitle = null
      this.mainledger.partnerSecretaryFirst = null
      this.mainledger.partnerSecretaryLast = null
      this.mainledger.partnerSecretaryEmail = null
      this.mainledger.partnerSecretaryPhone = null
      this.mainledger.partnerSecretaryContactOption = null
      this.mainledger.privatePerson = false
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    async addeditPartner () {
        this.mainledger.partnerVATID = this.mainledger.partnerVATID ? this.mainledger.partnerVATID.trim() : null
        this.mainledger.partnerName = this.mainledger.partnerName ? this.mainledger.partnerName.trim() : null
        if (this.mainledger.partnerName.length === 0) {
          this.text = 'Partner nema ime! Partner name is not defined!'
          this.color = 'red'
          this.snackbar = true
          return
        }
        if (this.mainledger.partnerVATID.length === 0) {
          this.text = 'Partner nema OIB! Partner VAT ID is not defined!'
          this.color = 'red'
          this.snackbar = true
          return
        }
        const addeditPartner = {...defNewpartner()}
        addeditPartner.CompanyId = this.mainledger.CompanyId ? this.mainledger.CompanyId : ''
        addeditPartner.CompanyNameDatabase = this.mainledger.CompanyNameDatabase
        addeditPartner.BusinessYear = this.mainledger.BusinessYear
        addeditPartner.UserId = this.mainledger.UserId
        addeditPartner.email = this.mainledger.email
        addeditPartner.First = this.mainledger.First
        addeditPartner.Last = this.mainledger.Last
        addeditPartner.partnerName = this.mainledger.partnerName
        addeditPartner.partnerNameShort = this.mainledger.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerNameDatabase = this.mainledger.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerBuildingNameNumber = this.mainledger.partnerBuildingNameNumber
        addeditPartner.partnerStreet = this.mainledger.partnerStreet
        addeditPartner.partnerCity = this.mainledger.partnerCity
        addeditPartner.partnerPostcode = this.mainledger.partnerPostcode
        addeditPartner.partnerCountyRegion = this.mainledger.partnerCountyRegion
        addeditPartner.partnerState = this.mainledger.partnerState
        addeditPartner.partnerVATID = this.mainledger.partnerVATID
        addeditPartner.partneremail = this.mainledger.partneremail
        addeditPartner.partnerPhones = this.mainledger.partnerPhones
        addeditPartner.partnerBankName = this.mainledger.partnerBankName
        addeditPartner.partnerBankAddress = this.mainledger.partnerBankAddress
        addeditPartner.partnerBankIBAN = this.mainledger.partnerBankIBAN
        addeditPartner.partnerBankSWIFT = this.mainledger.partnerBankSWIFT
        addeditPartner.partnerContactTime = this.mainledger.partnerContactTime
        addeditPartner.partnerSecretaryTitle = this.mainledger.partnerSecretaryTitle
        addeditPartner.partnerSecretaryFirst = this.mainledger.partnerSecretaryFirst
        addeditPartner.partnerSecretaryLast = this.mainledger.partnerSecretaryLast
        addeditPartner.partnerSecretaryEmail = this.mainledger.partnerSecretaryEmail
        addeditPartner.partnerSecretaryPhone = this.mainledger.partnerSecretaryPhone
        addeditPartner.partnerSecretaryContactOption = this.mainledger.partnerSecretaryContactOption
        addeditPartner.privatePerson = this.mainledger.privatePerson
        this.saving = true
        this.mainQuery2 = {}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.partnerVATID = this.mainledger.partnerVATID
        let toDo = null

        await PartnerService.check(this.mainQuery2)
            .then(res => {
              console.log('res', res)
              if (res.data && res.data.partner) {
                toDo = 'update'
                addeditPartner.id = res.data.partner.id
                this.mainledger.partnerId = res.data.partner.id
              }
              if (res.data && !res.data.partner) {
                toDo = 'addNew'
              }             
            })
            .catch(err => {
              toDo = 'addNew'
              this.error = err.data
              this.localError = false
            })

        if (toDo === 'update') {
            const updatedPartner = await PartnerService.put(addeditPartner)
            console.log('Update partner ', updatedPartner)
            this.text = 'Partner updated!'
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            //await checkPartners(1)
            await newUpdates(2)
            await testLastUpdate()
            if (this.$store.state.partnerStore) {
              this.partners = this.$store.state.partnerStore
            }
            this.selectId = this.mainledger.partnerId ? parseInt(this.mainledger.partnerId) : null    
            this.selectPa = this.selectId ? this.partners.find(partner => (partner.id === this.selectId)) : null
        }
        
        if (toDo === 'addNew') {
         
            const newPartner = await PartnerService.post(addeditPartner)
            this.selectPa = {}
            this.selectPa.id = newPartner.data.partner.id ? newPartner.data.partner.id : null
            this.selectPa.partnerName = newPartner.data.partner.partnerName ? newPartner.data.partner.partnerName : null
            this.text = this.lang.Partneradded
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            //await checkPartners(1)
            await newUpdates(2)
            await testLastUpdate()
            if (this.$store.state.partnerStore) {
              this.partners = this.$store.state.partnerStore
            }
            this.findPartner()
           
            // this.selectId = this.mainledger.partnerId ? parseInt(this.mainledger.partnerId) : null
            //this.selectPa = this.selectId ? this.partners.find(partner => (partner.partnerVATID === newPartner.data.partner.partnerVATID)) : null
        }
        this.saving = false
    },
    copyDate() {
      if (this.mainledger.InvoiceDate) {
        // this.mainledger.InvoiceDate = this.mainledger.BookingDate ? this.mainledger.BookingDate : dayjs(new Date()).format('YYYY-MM-DD')
        const haveYear = parseInt(this.mainledger.InvoiceDate.substr(0,4))
        const haveMonth = parseInt(this.mainledger.InvoiceDate.substr(5,2)) - 1
        const haveDay = parseInt(this.mainledger.InvoiceDate.substr(8,2))
        console.log(haveYear, haveMonth, haveDay)
        let d = new Date(haveYear + '-' + haveMonth + '-' + haveDay)
        console.log(d)
        this.mainledger.InvoicePaymentDeadlineDate = dayjs([d]).add(15, 'days').format('YYYY-MM-DD')
      }
    },
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days)
      const ret = result.toISOString().split('T')[0]
      return ret
    }
  },
  watch: {
    'mainledger.InvoiceDate' () {
      if (this.mainledger.InvoiceDate) {
        this.mainledger.InvoicePaymentDeadlineDate = this.addDays(this.mainledger.InvoiceDate, 15)
      }
    },

    'mainledger.InvoiceAmount' () {
      if (this.mainledger.InvoiceAmount && this.mainledger.InvoiceAmount !==0 && !this.backCalc) {
        this.mainledger.InvoiceForeginAmount = this.round((this.mainledger.InvoiceAmount * this.mainledger.ExRate),2)

        //this.calculateFirstAmount()
      }
    },

    'mainledger.InvoiceForeginAmount' () {
      if (this.mainledger.InvoiceForeginAmount && this.mainledger.InvoiceForeginAmount !==0 && this.backCalc) {
        this.mainledger.InvoiceAmount = this.round((this.mainledger.InvoiceForeginAmount / this.mainledger.ExRate),2)
        //this.calculateFirstAmount()
      }
    },

    'selectPa' () {
      
      this.findPartner()
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
