<template>
  <v-container fluid grid-list-md>

          <v-row>
            <v-col xs="4" >
              <h1>{{this.$store.state.documentActivLoc}}</h1>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="4" lg="6" xl="6" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="showStart(1)">
                  {{lang.showStartSupp}}
                </v-btn>
                <v-btn
                  text color="primary"
                  @click="showStart(2)">
                  {{lang.showStartBuyers}}
                </v-btn>
                <v-btn
                  text color="primary"
                  @click="showBook">
                  {{lang.showBook}}
                </v-btn>
                <v-btn
                  text color="primary"
                  @click="showView">
                  {{lang.showView}}
                </v-btn>
              </v-col>
          </v-row>
           <v-row no-gutters>
            <v-col xs="4" >

            </v-col>
            <v-col xs="2" >

            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4"  lg="4" xl="4" class="pr-3" v-if="dispBook">
              <v-spacer></v-spacer>
              <v-btn
                class="green mr-2"
                @click="goto(1)">
                {{lang.AddReceive2}}
              </v-btn>
              <v-btn
                class="primary"
                @click="goto(2)">
                {{lang.AddExpense2}}
              </v-btn>
            </v-col>
          </v-row>

          <br>
           <v-divider class="mt-1 mb-3"></v-divider>

          <v-row dense v-if="dispView">
              <v-col  sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3">

                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  width="290px"
                  :return-value.sync="startDate"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate1"
                      :label="langC.FromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"

                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="startDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog1.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col  sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-autocomplete
                    :items="partners"
                    v-model="selectPa"
                    :label="langC.SelectPartner"
                    item-text="partnerName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                  ></v-autocomplete>
              </v-col>


             <v-col  sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3" >
                <v-btn
                  text color="grey"
                  @click="copyPS(1)">
                  Prebaci PS Dob.
                </v-btn>
                <v-progress-circular v-if="this.saving"
                indeterminate
                color="green"
              ></v-progress-circular>
              </v-col>

              <v-col  sm="12" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="analyze(1)">
                  {{lang.DoCount}}
                </v-btn>
              </v-col>


               <v-col  sm="12" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="card(1)">
                  {{lang.partnerCardSupp}}
                </v-btn>
              </v-col>
              <v-col  sm="12" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="card(3)">
                  {{lang.partnerCardOpenSupp}}
                </v-btn>
              </v-col>

              <v-col  sm="12" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="openStatement(1)">
                  {{lang.BookExp2}}
                </v-btn>
              </v-col>

              <v-col sm="12" md="1" xs="1" lg="1" xl="1" v-if="displayEURselector">
                <v-checkbox v-model="showEUR" label="EUR"></v-checkbox>
              </v-col>
          </v-row>
          <v-row dense v-if="dispView">
               <v-col  sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  width="290px"
                  :return-value.sync="endDate"
                >
                 <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate2"
                      :label="langC.ToDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="endDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog2.save(endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col  sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3">
              </v-col>
              <v-col  sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3" >
                <v-btn
                  text color="grey"
                  @click="copyPS(2)">
                  Prebaci PS Kup.
                </v-btn>
                <v-progress-circular v-if="this.saving"
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </v-col>
              <v-col  sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="analyze(2)">
                  {{lang.Book2}}
                </v-btn>
              </v-col>
              <v-col  sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="card(2)">
                  {{lang.partnerCardBuy}}
                </v-btn>
              </v-col>
              <v-col  sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="card(4)">
                  {{lang.partnerCardOpenBuy}}
                </v-btn>
              </v-col>
              <v-col  sm="12" md="1" xs="1" lg="1" xl="1" >
                <v-btn
                  text color="primary"
                  @click="openStatement(2)">
                  {{lang.BookRec2}}
                </v-btn>
              </v-col>
          </v-row>


          <v-divider class="mt-1 mb-3"></v-divider>

          <div v-if="dispBook">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainBookCompList />
              </v-col>
            </v-row>
          </div>

          <v-row v-if="showCard1">
            <MainBookCompCard1 :items="items" :head="head" :exRate="exRate" :defCurrency="defCurrency"/>
          </v-row>

          <v-row v-if="showCard11">
            <MainBookCompCard2 :key="myDocumentSide" :head="head"  :partnerVATID="partnerVATID" :type="typeSide" :defCurrency="defCurrency" :showOpen="false"/>
          </v-row>
          <v-row v-if="showCard12">
            <MainBookCompCard2 :key="myDocumentSide" :head="head"  :partnerVATID="partnerVATID" :type="typeSide" :defCurrency="defCurrency" :showOpen="true"/>
          </v-row>

          <MainBookCompPrint ref="prnt" :kpi="kpi" />

          <div v-if="showCard3">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainLedgerBookIn :mainledgerAna="mainbookAna" :source="2" />
              </v-col>
            </v-row>
          </div>
          <div v-if="showCard4">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainLedgerBookOut :mainledgerAna="mainbookAna" :source="2" />
              </v-col>
            </v-row>
          </div>

          <v-row v-if="showCard10">
            <MainBookCompCard :items="tItems" :head="head" :fTotal1="fTotal1" :fTotal2="fTotal2" :fTotal3="fTotal3" :fTotal4="fTotal4" :fTotal5="fTotal5" :fTotal6="fTotal6" :type="typeCard" :defCurrency="defCurrency" :hideLinks="hideLinks"/>
          </v-row>

          <v-row v-if="dispType">
            <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
              <MainBookCompPSList :key="myDocumentSide" :type="typeCard" :defCurrency="defCurrency" />
            </v-col>
          </v-row>

       <div v-if="showDialog">
        <Dialog :header="header" :message="message" :dcolor="dcolor" :showDialog="showDialog"/>
       </div>
  </v-container>
</template>

<script>
import MainBookCompList from './MainBookCompList'
import MainBookCompService from '@/services/MainBookCompService'
import MainBookCompCard1 from './MainBookCompCard1'
import PartnerService from '@/services/PartnerService'
import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { v4 as uuidv4 } from 'uuid'
import {parseNum, round, dateFormat,dynamicSort} from '@/snippets/allSnippets'
import MainLedgerBookIn from '../MainLedger/MainLedgerBookIn'
import MainLedgerBookOut from '../MainLedger/MainLedgerBookOut'
import MainBookCompPrint from './MainBookCompPrint'
import MainBookCompCard from './MainBookCompCard'
import MainBookCompCard2 from './MainBookCompCard2'
import MainBookCompPSList from './MainBookCompPSList'
import BankAccountService from '@/services/BankAccountService'
import MainLedgerService from '@/services/MainLedgerService'
import { defNew, defMainQuery, defMainLedger } from '../MainLedger/mainledgerHelper'
import Api from '@/services/Api'
import { defMainBook, defNewMainBook } from '../MainLedger/mainledgerHelper'
import Dialog from  '../../snippets/Dialog'
import dayjs from 'dayjs'

export default {
  name: 'mainbookcompheader',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      hideLinks: false,
      showDialog: false,
      header: '',
      message: '',
      dcolor: '',
      defCurrency: '',
      exRate: 1,
      showEUR: false,
      displayEURselector: false,
      euroChanged: false,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      startDate: null,
      endDate: null,
      mainbookAna: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        fromDate: null,
        toDate: null,
      },
      comp: {},
      kpi: {},
      error: null,
      localError: false,
      size: 'sm',
      account4Loc: [],
      account4LocFrom: [],
      account4LocTo: [],
      partners: [],
      items: [],
      bsitems: [],
      printitems: [],
      expitems: [],
      docs: [],
      CompanyId: 0,
      selectDoc: {},
      selectPa: {},
      selectA4: {},
      selectA4from: {},
      selectA4to: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: '',
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      showCard1: false,
      showCard2: false,
      showCard3: false,
      showCard4: false,
      showCard5: false,
      showCard6: false,
      showCard7: false,
      showCard10: false,
      showCard11: false,
      showCard12: false,
      showStartType: 1,
      dispType: false,
      bookType: [],
      bookTypeSelected: {
      },
      bookTypeSelectedName: 'TEM',
      sameAsFrom: false,
      RecCash: 0,
      RecBank: 0,
      RecFree: 0,
      RecVAT: 0,
      RecTotal: 0,
      ExpCash: 0,
      ExpBank: 0,
      ExpFree: 0,
      ExpVAT: 0,
      ExpSpecial: 0,
      ExpTotal: 0,
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      banks: [],
      selectBank: {},
      selectBankId: 0,
      somefile: null,
      MLFree2: null,
      MLFree3: null,
      MLFree4: null,
      MLFree5: null,
      mainbook: { },
      types: [],
      select: {},
      dispView: false,
      dispBank: false,
      dispBook: true,
      mainledgerAna: {},
      pItems: [],
      payments: [],
      tItems: [],
      head: {},
      fTotal1: 0,
      fTotal2: 0,
      fTotal3: 0,
      fTotal4: 0,
      fTotal5: 0,
      fTotal6: 0,
      typeCard: 0,
      myDocumentSide: 1,
      typeSide: 1,
      partnerVATID: '',
      mainledger: { },
      newBussYear: 0,
      invoices: []
    }
  },
  components: {
    MainBookCompCard1,
    MainLedgerBookIn,
    MainLedgerBookOut,
    MainBookCompPrint,
    MainBookCompList,
    MainBookCompCard,
    MainBookCompCard2,
    Dialog,
    MainBookCompPSList
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }

    this.displayEURselector =  this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? true : false
    this.defCurrency = this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? 'Kn' : 'EUR'
    this.exRate = this.showEUR ?  this.$store.state.defExRate : 1

    // need five arrays with partners, employes, tasktype, taskdepartment, users
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
      this.mainbookAna.CompanyId = this.$store.state.companyid
    }
    this.comp = this.$store.state.companyActiv
    if (this.CompanyId > 0) {
      this.partners = this.$store.state.partnerStore ? this.$store.state.partnerStore : []
    }
    if (this.$store.state.user.id) {
      this.mainbookAna.UserId = this.$store.state.user.id
    }
    if (this.$store.state.user.email) {
      this.mainbookAna.email = this.$store.state.user.email
    }
    if (this.$store.state.user.First) {
      this.mainbookAna.First = this.$store.state.user.First
    }
    if (this.$store.state.user.Last) {
      this.mainbookAna.Last = this.$store.state.user.Last
    }
    this.mainbookAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
    this.mainbookAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
    this.mainbookAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
    this.newBussYear = this.$store.state.businessYear ? parseInt(this.$store.state.businessYear)+1 : 2024
    let locDate1 = new Date()
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
    this.startDate = dayjs([this.mainbookAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
    this.endDate = dayjs([this.mainbookAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.banks = (await BankAccountService.choose(this.mainbookAna)).data.bankAccounts
    if (this.banks) {
      this.selectBank = this.banks ? this.banks.find(bank => (bank.DefaultAccount === true)) : {}
      this.selectBankId  = this.selectBank ? parseInt(this.selectBank.id) : null
      this.mainbookAna.selectBankId  = this.selectBank ? parseInt(this.selectBank.id) : null
    }
    // const {data} = (await MainBookTypeService.index(this.mainbookAna))
    // this.types = data.mainbooktypes && data.mainbooktypes.length > 0 ? data.mainbooktypes : []
    // const haveDocs = (await MainBookDocService.index(this.mainbookAna)).data.mainbookdocs
    // this.docs = haveDocs ? haveDocs : []

  },
  computed: {
    formIsValid () {
      return this.startDate !== null &&
        this.endDate !== null &&
        this.mainbookAna.CompanyId !== ''
    },
    formatDate1() {
      return this.startDate
        ? dateFormat(this.startDate)
        : "";
    },
    formatDate2() {
      return this.endDate
        ? dateFormat(this.endDate)
        : "";
    },
  },
  methods: {
    async copyPS(type) {
      const forUpdate = []
      await this.openStatement(type, 'PS')
      let cond = 0
      if (this.tItems && this.tItems.length > 0) {
        this.tItems.sort(dynamicSort("partnerName"))
        this.tItems.map(item => {
          if (item.allOpen && item.allOpen > 0.08) {
            this.mainledger = defMainLedger()
            this.mainledger = {...this.mainledger, ...defNew()}

            this.mainledger.BookingDate = dayjs([this.newBussYear]).startOf('year').format('YYYY-MM-DD')
            this.mainledger.InvoiceDate = this.mainledger.BookingDate
            this.mainledger.InvoicePaymentDeadlineDate = this.mainledger.BookingDate
            this.mainledger.InvoiceAmount = round(item.allOpen , 2)

            cond += 1
            const fullNr = '000000'
            const rightNr = cond.toString()
            const rightNrLen = rightNr.toString().length
            const onlyZeros = fullNr.substring(0, (6-rightNrLen))
            const newBookID = this.newBussYear.toString() + onlyZeros + rightNr
            this.mainledger.BookID = parseInt(newBookID)

            const doc = type === 1  ? 'BookInInvoices' : 'BookOutInvoices'
            // const docLoc = this.type === 1  ? 'Početno stanje URE' : 'Početno stanje IRE'

            this.mainledger.BusinessYear = this.newBussYear
            this.mainledger.documentName = doc
            this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.newBussYear + '-' + doc + '-' + this.mainledger.BookID 
            this.mainledger.BookIdGeneral = this.mainledger.documentIdGeneral
            if (type === 1) {
              this.mainledger.MLType = 1
              this.mainledger.BookTypeID = 101
              this.mainledger.BookName = 'URE_PS'
            }
            if (type === 2) {
              this.mainledger.MLType = 2
              this.mainledger.BookTypeID = 102
              this.mainledger.BookName = 'IRE_PS'
            }

            const pr = item.partner
            this.mainledger.partnerId = pr.id ? pr.id : null
            this.mainledger.partnerName = pr.partnerName ? pr.partnerName : null
            this.mainledger.partnerNameShort = pr.partnerNameShort ? pr.partnerNameShort : null
            this.mainledger.partnerNameDatabase = pr.partnerNameDatabase ? pr.partnerNameDatabase : null
            this.mainledger.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null
            this.mainledger.partnerStreet = pr.partnerStreet ? pr.partnerStreet : null
            this.mainledger.partnerCity = pr.partnerCity ? pr.partnerCity : null
            this.mainledger.partnerPostcode = pr.partnerPostcode ? pr.partnerPostcode : null
            this.mainledger.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null
            this.mainledger.partneremail = pr.partneremail ? pr.partneremail : null
            this.mainledger.partnerPhones = pr.partnerPhones ? pr.partnerPhones : null
            this.mainledger.partnerBankName = pr.partnerBankName ? pr.partnerBankName : null
            this.mainledger.partnerBankAddress = pr.partnerBankAddress ? pr.partnerBankAddress : null
            this.mainledger.partnerBankIBAN = pr.partnerBankIBAN ? pr.partnerBankIBAN : null
            this.mainledger.partnerBankSWIFT = pr.partnerBankSWIFT ? pr.partnerBankSWIFT : null

            forUpdate.push(this.mainledger)
          }
        })

        if (forUpdate && forUpdate.length > 0) {

          const {data} = (await MainLedgerService.postbulk(forUpdate))
          if (data && data.mainledgers) {
            this.allSavedHeads = [...data.mainledgers]
          } else {
            console.log('Error on save bulk mainledgers')
          }
          console.log('forUpdate >>>>>>>', forUpdate)
          this.header = 'OK'
          this.message = 'Pocetnja stanja su upisana!'
          this.dcolor = 'green'
          this.showDialog = true
        }

      }
    },
    async copyPSOLD(type) {
      this.saving = true
      this.mainbookAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainbookAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainbookAna.docFromDate = this.startDate
      this.mainbookAna.docToDate = this.endDate
      this.mainbookAna.MLType = type ? type : null
      let mbook = []
      await MainBookCompService.choose(this.mainbookAna)
      .then(res => {
        if (res && res.data && res.data.mainbookcomps) {
          mbook = res.data.mainbookcomps
          console.log('Izvoda imam', mbook.length)
        }
      })
      .catch(err => {
        console.log('MainbookComp service err', err)
      })
      //const mledger = (await MainLedgerService.books(this.mainbookAna)).data.documents
      this.invoices.length = 0
      await MainLedgerService.books(this.mainbookAna)
      .then(res => {
        if (res && res.data && res.data.documents && res.data.documents.length> 0) {
          this.invoices = res.data.documents
          console.log('this.invoices  imam', this.invoices.length)
        }
      })
      .catch((err) => {
        console.log('MainledgerService', err)
      })
      
      this.pItems.length = 0

      // if (mbook && mbook.length > 0) {
      //   mbook.map(book => {
      //     let doIt = false
      //     if (this.type === 1 && round(parseNum(book.ExpTotal),2) !== 0 ) {
      //       doIt = true
      //     }
      //     if (this.type === 2 && round(parseNum(book.RecTotal),2) !== 0 ) {
      //       doIt = true
      //     }
      //     if (doIt){
      //       const object1 = {
      //         datum: book.BookDate,
      //         datum2: dayjs(book.BookDate).format('YYYY-MM-DD'),
      //         BookTypeName: 'Izvod',
      //         InvoiceNumber: book.bankStatementNumber ? book.bankStatementNumber : 0,
      //         InvoiceDate: book.BookDate,
      //         InvoicePaymentDeadlineDate: book.InvoicePaymentDeadlineDate,
      //         Debit: (this.type === 1 ? book.ExpTotal : 0 ),
      //         Credit: (this.type === 2 ? book.RecTotal  : 0 ),
      //         desc1: book.BookDesc1,
      //         desc2: book.BookDesc2,
      //         docID: parseInt(book.BookID),
      //         type: 'payment'
      //       }
      //       this.pItems.push(object1)
      //     }
      //   })
      // }

      if (this.invoices && this.invoices.length > 0) {
        const forUpdate = []
        this.invoices.map(book => {
          const cond = parseInt(book.BookID)
          const amount = round(book.InvoiceAmount,2)
          let payed = 0
          const havePayments = mbook.filter(f => f.BookID === cond)
          if (havePayments && havePayments.length > 0) {
            havePayments.map(paym => {
              payed += type === 1 ? round(paym.ExpTotal,2) : round(paym.RecTotal,2)
            })
          }
          if (round(Math.abs(amount)-Math.abs(payed) , 2) !== 0) {
            const newPs = {...book}
            delete newPs.id
            newPs.BookingDate = dayjs([this.newBussYear]).startOf('year').format('YYYY-MM-DD')
            newPs.InvoiceAmount = round(Math.abs(amount)-Math.abs(payed) , 2)

            const fullNr = '000000'
            const rightNr = cond.toString()
            if (rightNr.length < 6) {
              const rightNrLen = rightNr.toString().length
              const onlyZeros = fullNr.substring(0, (6-rightNrLen))
              const newBookID = this.newBussYear.toString() + onlyZeros + rightNr
              newPs.BookID = parseInt(newBookID)
            }
            if (rightNr.length >= 6) {
              newPs.BookID = parseInt(cond)
            }
            const doc = type === 1  ? 'BookInInvoices' : 'BookOutInvoices'
            // const docLoc = this.type === 1  ? 'Početno stanje URE' : 'Početno stanje IRE'

            newPs.BusinessYear = this.newBussYear
            newPs.documentName = doc
            newPs.documentIdGeneral = this.$store.state.companyName + '-' + this.newBussYear + '-' + doc + '-' + newPs.BookID 
            newPs.BookIdGeneral = newPs.documentIdGeneral
            if (type === 1) {
              newPs.MLType = 1
              newPs.BookTypeID = 101
              newPs.BookName = 'URE_PS'
            }
            if (type === 2) {
              newPs.MLType = 2
              newPs.BookTypeID = 102
              newPs.BookName = 'IRE_PS'
            }
            forUpdate.push(newPs)
          }

        })
        console.log('forUpdate', forUpdate.length)

        if (forUpdate && forUpdate.length > 0) {
          forUpdate.sort(dynamicSort("partnerName"))
          const {data} = (await MainLedgerService.postbulk(forUpdate))
          if (data && data.mainledgers) {
            this.allSavedHeads = [...data.mainledgers]
          } else {
            console.log('Error on save bulk mainledgers')
          }
          // let results = forUpdate.map(async(forU) => {
          //     await MainLedgerService.postbulk(forU)
          //   })
          //  results = await Promise.all(results)
            this.header = 'OK'
            this.message = 'Pocetnja stanja su upisana!'
            this.dcolor = 'green'
            this.showDialog = true
        }
      }
      this.saving = false
      // -----------------------------  kad imam racune i izvode onda da vidim koji su otvoreni 

      // const result = [...this.pItems.reduce((r, o) => {
      // const key = o.docID;
      
      // const item = r.get(key) || Object.assign({}, o, {
      //     Debit: 0,
      //     Credit: 0
      //   });
        
      //   item.Debit += o.Debit;
      //   item.Credit += o.Credit;

      //   return r.set(key, item);
      // }, new Map).values()];

      // console.log('result', result)
      //const forUpdate = []
  
      // result.map(r => {
      //   if (round(Math.abs(r.Debit)-Math.abs(r.Credit) , 2) !== 0) {
      //     for (const prop of Object.getOwnPropertyNames(this.mainledger)) {
      //       delete this.mainledger[prop];
      //     }
      //     this.mainledger = mledger.find(doc => parseInt(doc.BookID) === r.docID)
      //     // find in books
      //     if (this.mainledger) {
      //       this.mainledger.BookingDate = dayjs([this.newBussYear]).startOf('year').format('YYYY-MM-DD')
      //       this.mainledger.InvoiceAmount = round(Math.abs(r.Debit)-Math.abs(r.Credit) , 2)

      //       const fullNr = '000000'
      //       const rightNr = r.docID.toString()
      //       if (rightNr.length < 6) {
      //         const rightNrLen = rightNr.toString().length
      //         const onlyZeros = fullNr.substring(0, (6-rightNrLen))
      //         const newBookID = this.newBussYear.toString() + onlyZeros + rightNr
      //         this.mainledger.BookID = parseInt(newBookID)
      //       }
      //       if (rightNr.length >= 6) {
      //         this.mainledger.BookID = parseInt(r.docID)
      //       }
      //       const doc = this.type === 1  ? 'BookInInvoices' : 'BookOutInvoices'
      //       // const docLoc = this.type === 1  ? 'Početno stanje URE' : 'Početno stanje IRE'

      //       this.mainledger.BusinessYear = this.newBussYear
      //       this.mainledger.documentName = doc
      //       this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.newBussYear + '-' + doc + '-' + this.mainledger.BookID 
            
      //       if (this.type === 1) {
      //         this.mainledger.MLType = 1
      //         this.mainledger.BookTypeId = 101
      //         this.mainledger.BookName = 'URE_PS'
      //       }
      //       if (this.type === 2) {
      //         this.mainledger.MLType = 2
      //         this.mainledger.BookTypeId = 102
      //         this.mainledger.BookName = 'IRE_PS'
      //       }
      //       forUpdate.push(this.mainledger)
      //     }


      //   }
      // })
      

    },
    mainbooktypes() {
      this.dispBook = false
      this.showCard7 = false
      this.dispView = false
      this.dispBank = false
      this.dispType = false
      this.showCard6 = !this.showCard6
    },
    mainbookdocs() {
      this.showCard7 = !this.showCard7
      this.dispBook = false
      this.showCard6 = false
      this.dispView = false
      this.dispBank = false
      this.dispType = false
      this.$store.dispatch('setDocumentActiv', 'MainBookComps')
      this.$store.dispatch('setDocumentActivLoc', 'Knjiga uplata-isplata')
    },
    showBook() {
      this.dispBook = !this.dispBook
      this.showCard1 = false
      this.showCard2 = false
      this.showCard3 = false
      this.showCard4 = false
      this.showCard5 = false
      this.showCard6 = false
      this.showCard7 = false
      this.showCard11 = false
      this.showCard12 = false
      this.dispView = false
      this.dispBank = false
      this.dispType = false
      this.$store.dispatch('setDocumentActiv', 'MainBookComps')
      this.$store.dispatch('setDocumentActivLoc', 'Knjiga uplata-isplata')
    },
    showView() {
      this.dispBook = false
      this.setAllFalse()
      this.dispType = false
      this.dispBank = false
      this.dispView = !this.dispView
      this.$store.dispatch('setDocumentActiv', 'MainBookComps')
      this.$store.dispatch('setDocumentActivLoc', 'Knjiga uplata-isplata')
    },
    showBank() {
      this.dispBook = false
      this.setAllFalse()
      this.dispType = false
      this.dispView = false
      this.dispBank = !this.dispBank
      this.$store.dispatch('setDocumentActiv', 'MainBookComps')
      this.$store.dispatch('setDocumentActivLoc', 'Knjiga uplata-isplata')
    },
    showStart(type) {
      this.dispBook = false
      this.showCard1 = false
      this.showCard2 = false
      this.showCard3 = false
      this.showCard4 = false
      this.showCard5 = false
      this.showCard6 = false
      this.showCard7 = false
      this.showCard10 = false
      this.showCard11 = false
      this.showCard12 = false
      this.dispView = false
      this.dispBank = false

      this.showStartType = type === 1 ? 1 : 2
      const doc = type === 1  ? 'BookInInvoices' : 'BookOutInvoices'
      const docLoc = type === 1  ? 'Početno stanje URE' : 'Početno stanje IRE'
      const bookTypeSelected = {}
      bookTypeSelected.text = type === 1  ? 'URE_PS' : 'IRE_PS'
      bookTypeSelected.value = type === 1  ? 101 : 102
      this.$store.dispatch('setDocumentActivHead', {})
      this.$store.dispatch('setDocumentActivItems', [])
      this.$store.dispatch('setActionType', 0)
      this.$store.dispatch('setDocumentActiv', doc)
      this.$store.dispatch('setDocumentActivLoc', docLoc)
      this.$store.dispatch('setDocumentSide', type)
      this.$store.dispatch('setbookTypeSelected', bookTypeSelected)
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      query.MLType = type
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      this.$store.dispatch('setOnPage', query)
      this.currentPath = '/crm/mainbookcompindex'
      this.currentDoc = doc

      this.dispType = true
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getList  (item, queryText, itemText) {
      return itemText.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase())
    },

    async goto (param) {
      try {
        this.$store.dispatch('setTaskTypeid', param)
        this.$store.dispatch('seteditItem', 0)
        this.$store.dispatch('setpathAgain', 'mainledgercompindex')
        await this.$router.push({
          name: 'mainbookcompcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    card(type) {
      console.log('1 Pozivam card type', type)
      this.error = null
      this.myDocumentSide +=1
      this.setAllFalse()

      let doBack = true
      this.head = {}
      if (this.selectPa && this.selectPa.partnerVATID) {
        this.partnerVATID = this.selectPa.partnerVATID
        this.head.partnerVATID = this.selectPa.partnerVATID
        doBack = false
      }
      if (doBack) {
        alert('ERROR! Partner not selected!')
        return
      }
      this.head.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.head.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.head.docFromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') : null
      this.head.docToDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : null
      let typeSide = 1
      if (type === 1 || type === 2) {
        typeSide = type
      }
      if (type === 3 || type === 4) {
        typeSide = type === 3 ? 1 : 2
      }
      this.head.MLType = typeSide ? typeSide : null
      this.typeSide = typeSide
      this.showCard11 = type === 1 || type === 2 ? true : false
      this.showCard12 = type === 3 || type === 4 ? true : false
      console.log('2 Pozivam card this.showCard11', this.showCard11)
      console.log('3 Pozivam card this.showCard12', this.showCard12)

    },
    async analyze (type) {
      try {

        // do analysis from database
        this.error = null
        this.localError = false
        this.showCard1 = false
        this.showCard2 = false
        this.showCard3 = false
        this.showCard4 = false
        this.showCard5 = false
        this.showCard10 = false
        this.showCard11 = false
        this.showCard12 = false
        this.showDialog = false
        // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open
        // this.mainbookAna.type = type
        if (this.selectPa.id) {
          this.mainbookAna.partnerId = this.selectPa.id
        }
        if (this.select.id) {
          this.mainbookAna.BookTypeId = this.select.id
        }
        if (this.selectDoc.id) {
          this.mainbookAna.BookDocId = this.selectDoc.id
        }
        this.mainbookAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainbookAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainbookAna.docFromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') : null
        this.mainbookAna.docToDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : null

        if (type === 1 || type === 2) {
          await MainBookCompService.choose(this.mainbookAna)
          .then(res => {
            this.items = res.data.mainbookcomps ? res.data.mainbookcomps : []

            if (this.items) {
              this.showCard1 = type === 1 ? true : false
              this.showCard2 = type === 2 ? true : false
              if (type === 2)
              {
                this.printKpi()
              }
            }
          })
          .catch(err => {
            this.header = 'Error!!'
            this.message = err
            this.dcolor = 'red'
            this.showDialog = true
          })
        }
        if (type === 3 ) {
          this.showCard3 = !this.showCard3
        }
        if (type === 4 ) {
          this.showCard4 = !this.showCard4
        }

      } catch (error) {
        this.header = 'Error!!'
        this.message = error
        this.dcolor = 'red'
        this.showDialog = true
      }
    },
    async printKpi(){
      this.mountKpi()
      this.initKpi()
      this.$refs.prnt.print(this.kpi)
    },
    mountKpi() {
        this.RecCash = 0
        this.RecBank = 0
        this.RecFree = 0
        this.RecVAT = 0
        this.RecTotal = 0

        this.ExpCash = 0
        this.ExpBank = 0
        this.ExpFree = 0
        this.ExpVAT = 0
        this.ExpSpecial = 0
        this.ExpTotal = 0
        this.Total1 = 0
        this.Total2 = 0
        this.Total3 = 0
        this.Total4 = 0
        this.Total5 = 0
        this.Total6 = 0

        if (this.items) {
          this.items.map(item => {
            this.RecCash += round((parseNum(item.RecCash) / this.exRate) ,2)
            this.RecBank += round((parseNum(item.RecBank)  / this.exRate) ,2)
            this.RecFree += round((parseNum(item.RecFree) / this.exRate) ,2)
            this.RecVAT += round((parseNum(item.RecVAT) / this.exRate) ,2)
            this.RecTotal += round((parseNum(item.RecTotal) / this.exRate) ,2)

            this.ExpCash += round((parseNum(item.ExpCash) / this.exRate) ,2)
            this.ExpBank += round((parseNum(item.ExpBank) / this.exRate) ,2)
            this.ExpFree += round((parseNum(item.ExpFree) / this.exRate) ,2)
            this.ExpVAT += round((parseNum(item.ExpVAT) / this.exRate) ,2)
            this.ExpSpecial += round((parseNum(item.ExpSpecial) / this.exRate) ,2)
            this.ExpTotal += round((parseNum(item.ExpTotal) / this.exRate) ,2)
          })
        }
        this.Total1 = this.RecCash - this.ExpCash
        this.Total2 = this.RecBank - this.ExpBank
        this.Total3 = this.RecFree - this.ExpFree
        this.Total4 = this.RecVAT - this.ExpVAT
        this.Total5 = this.ExpSpecial
        this.Total6 = this.RecTotal - this.ExpTotal
    },
    initKpi() {
      this.kpi = {}
      this.kpi.Identifikator = uuidv4()
      this.kpi.OIB = this.$store.state.companyActiv.CompanyVATID ? this.$store.state.companyActiv.CompanyVATID : ''
      this.kpi.Naziv = this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ''
      this.kpi.Mjesto = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
      this.kpi.Ulica = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
      this.kpi.Broj = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
      this.kpi.DodatakKucnomBroju = ''
      this.kpi.DatumOd = this.startDate ? this.startDate : null
      this.kpi.DatumDo = this.endDate ? this.endDate : null
      this.kpi.PodrucjeDjelatnosti = this.$store.state.companyActiv.CompanyInvoiceType ? this.$store.state.companyActiv.CompanyInvoiceType : ''
      this.kpi.SifraDjelatnosti = this.$store.state.companyActiv.CompanyInvoiceHint ? this.$store.state.companyActiv.CompanyInvoiceHint : ''
      this.kpi.Ime = this.$store.state.user.First ? this.$store.state.user.First : ''
      this.kpi.Prezime = this.$store.state.user.Last ? this.$store.state.user.Last : ''
      this.kpi.Ukupno = {}
      this.kpi.Ukupno.RecCash = this.RecCash ? round(this.RecCash, 2)  : 0
      this.kpi.Ukupno.RecBank = this.RecBank ? round(this.RecBank, 2)  : 0
      this.kpi.Ukupno.RecFree = this.RecFree ? round(this.RecFree, 2)  : 0
      this.kpi.Ukupno.RecVAT = this.RecVAT ? round(this.RecVAT, 2)  : 0
      this.kpi.Ukupno.RecTotal = this.RecTotal ? round(this.RecTotal, 2)  : 0

      this.kpi.Ukupno.ExpCash = this.ExpCash ? round(this.ExpCash, 2)  : 0
      this.kpi.Ukupno.ExpBank = this.ExpBank ? round(this.ExpBank, 2)  : 0
      this.kpi.Ukupno.ExpFree = this.ExpFree ? round(this.ExpFree, 2)  : 0
      this.kpi.Ukupno.ExpVAT = this.ExpVAT ? round(this.ExpVAT, 2)  : 0
      this.kpi.Ukupno.ExpSpecial = this.ExpSpecial ? round(this.ExpSpecial, 2)  : 0
      this.kpi.Ukupno.ExpTotal = this.ExpTotal ? round(this.ExpTotal, 2)  : 0

      this.kpi.Ukupno.Total1 = this.Total1 ? round(this.Total1, 2)  : 0
      this.kpi.Ukupno.Total2 = this.Total2 ? round(this.Total2, 2)  : 0
      this.kpi.Ukupno.Total3 = this.Total3 ? round(this.Total3, 2)  : 0
      this.kpi.Ukupno.Total4 = this.Total4 ? round(this.Total4, 2)  : 0
      this.kpi.Ukupno.Total5 = this.Total5 ? round(this.Total5, 2)  : 0
      this.kpi.Ukupno.Total6 = this.Total6 ? round(this.Total6, 2)  : 0
      this.kpi.RacuniIspis = this.items ? this.items.slice() : []
      this.kpi.exRate = this.exRate
    },
    close () {
      this.show = false
    },
    onUpload() {

        if (!this.selectBank.id) {
          this.header = 'Učitavanja izvoda - Greška'
          this.message = 'Izvod NIJE učitan. Nije odabrana niti jedna banka! '
          this.dcolor = 'red'
          this.showDialog = true
          return
        }
        this.showDialog = false
        this.MLFree2 = ''
        this.MLFree3 = ''
        this.MLFree4 = ''
        this.MLFree5 = ''
        const newO = {}
        this.mainbookAna = {...newO}
        if (this.somefile) {
          let fd = new FormData()
          fd.append('companyId', this.$store.state.companyid)
          fd.append('originalName', this.somefile.name)
          fd.append('typeDoc', 'BS')
          fd.append('myFile', this.somefile, this.somefile.name)
          this.somefile = null


          Api().post('bookin/invoice', fd).then(response => {
              this.data = response.data
              if (this.data) {

                this.mainbookAna.MLFree2 = this.data.originalname ? this.data.originalname : ''
                this.mainbookAna.MLFree3 = this.data.filename ? this.data.filename : ''
                this.mainbookAna.MLFree4 = this.data.destination ? this.data.destination : ''
                this.mainbookAna.MLFree5 = this.data.path ? this.data.path : ''
                this.mainbookAna.bankAccountID = this.selectBankId ? this.selectBankId : ''
                //this.readbsml()
              }
          }).catch(error => {
            this.header = 'Učitavanja izvoda - Greška'
            this.message = 'Izvod NIJE učitan. Krivi tip datoteke. ' + error
            this.dcolor = 'red'
            this.showDialog = true
          });
        } else {
          this.header = 'Error!!'
          this.message = this.lang.BSfileError
          this.dcolor = 'red'
          this.showDialog = true
        }
    },
    async readbsml() {
      try {
        let doNotRead = false

        await MainLedgerService.readbsml(this.mainbookAna).then(async(res) => {

          this.ml = res.data.allData
          const checkBS = {}
          checkBS.bankAccountID  = this.selectBank.id
          checkBS.bankStatmentNumber  = this.ml.nine7[0].RedniBrojIzvatka4
          checkBS.bankStatmentDate  = this.ml.nine7[0].DatumIzvatka6.trim().substr(0,10)
          checkBS.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
          checkBS.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
          await MainBookCompService.checkbs(checkBS)
          .then(res => {
            if (res && res.data && res.data.mainbookcomps && res.data.mainbookcomps.length > 0) {

              const goBack = confirm('Izvod sa tim brojem je već učitan. Ima stavaka: ' + res.data.mainbookcomps.length.toString() + ' . Da li ste sigurni da ga svejedno želite učitati?')
              if (goBack === false) {
                doNotRead = true
              }
            }
          })
          .catch(err => {
            this.header = 'Error!!'
            this.message = 'Greska u provjeri izvoda ' + err
            this.dcolor = 'red'
            this.showDialog = true
            return
          })
          if (doNotRead === true) {
            return
          }

          //this.mainledger.bankAccountID = this.mainbookAna.selectBankId
          this.bsitems = []
          //let nr = 0
          if (this.ml.nine5) {
            this.ml.nine5.map(nine5 => {
              this.mainbook = defMainBook()
              this.mainbook = {...this.mainbook, ...defNewMainBook()}
              //nr += 1
              console.log('nine5.PozivNaBrojPrimatelja15', nine5.PozivNaBrojPrimatelja15)
              this.mainbook.BookDate = this.ml.nine7[0].DatumIzvatka6
              this.mainbook.BookDesc1 = 'Izvod br: ' + this.ml.nine7[0].RedniBrojIzvatka4 + ',' + dayjs(this.ml.nine7[0].DatumIzvatka6).format('DD.MM.YYYY') + ',' + (nine5.ValutaPokrica ? nine5.ValutaPokrica : '')
              this.mainbook.partnerName  = nine5.NazivPrimatelja3 ? nine5.NazivPrimatelja3 : ''
              this.mainbook.partnerBankIBAN  = nine5.RacunPrimatelja2 ? nine5.RacunPrimatelja2 : ''
              this.mainbook.bankAccountID  = this.selectBank.id
              this.mainbook.bankStatmentNumber  = this.ml.nine7[0].RedniBrojIzvatka4
              this.mainbook.bankStatmentDate  = this.ml.nine7[0].DatumIzvatka6.trim().substr(0,10)
              this.mainbook.BookDesc2 = 'Poziv: ' + (nine5.PozivNaBrojPrimatelja15.substr(0, 4) ? nine5.PozivNaBrojPrimatelja15.substr(0, 4) : '') + ' ' + (nine5.PozivNaBrojPrimatelja15 && nine5.PozivNaBrojPrimatelja15.length > 4 ? nine5.PozivNaBrojPrimatelja15.substr(4, nine5.PozivNaBrojPrimatelja15.length) : '')
              // this.mainbook.bankStatmentNumberDetail = nr
              // this.mainbook.bankReference1  = nine5.PozivNaBrojPlatitelja14.substr(0, 4) ? nine5.PozivNaBrojPlatitelja14.substr(0, 4) : ''
              // this.mainbook.bankReference2  = nine5.PozivNaBrojPlatitelja14 && nine5.PozivNaBrojPlatitelja14.length > 4 ? nine5.PozivNaBrojPlatitelja14.substr(4, nine5.PozivNaBrojPlatitelja14.length) : ''
              // this.mainbook.bankCall1  = nine5.PozivNaBrojPrimatelja15.substr(0, 4) ? nine5.PozivNaBrojPrimatelja15.substr(0, 4) : ''
              // this.mainbook.bankCall2  = nine5.PozivNaBrojPlatitelja15 && nine5.PozivNaBrojPlatitelja15.length > 4 ? nine5.PozivNaBrojPlatitelja15.substr(4, nine5.PozivNaBrojPlatitelja15.length) : ''
              // 10 isplata  20 uplata
              this.mainbook.ExpBank  = nine5.OznakaTransakcije1 === "10" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.ExpTotal  = nine5.OznakaTransakcije1 === "10" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.RecBank  = nine5.OznakaTransakcije1 === "20" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.RecTotal  = nine5.OznakaTransakcije1 === "20" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.ExpBank  = nine5.OznakaTransakcije1 === "10" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.BookRecExp  = nine5.OznakaTransakcije1 === "20" ? 1 : 2
              if (this.mainbook.partnerBankIBAN){
                const cIban = {
                  partnerBankIBAN: this.mainbook.partnerBankIBAN
                }
                PartnerService.checkIBAN(cIban)
                .then(res => {
                  if (res.data.partner) {
                    const pr = res.data.partner
                    this.mainbook.partnerId = pr.id ? pr.id : null
                    this.mainbook.partnerName = pr.partnerName ? pr.partnerName : null
                    this.mainbook.partnerNameShort = pr.partnerNameShort ? pr.partnerNameShort : null
                    this.mainbook.partnerNameDatabase = pr.partnerNameDatabase ? pr.partnerNameDatabase : null
                    this.mainbook.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null

                  }
                })
              }
              this.bsitems.push(this.mainbook)
            })
          }
          if (this.bsitems.length > 0) {
            await MainBookCompService.saveitems(this.bsitems)
            this.$store.dispatch('setResetList', this.$store.state.resetList + 1)

            this.header = 'Učitavanja izvoda'
            this.message =  'Izvod uspješno učitan.'
            this.dcolor =  'green'
            this.showDialog = true
            console.log('prikazujem dijalog', this.showDialog)
          }

        }).catch(error => {
          this.header = 'Učitavanja izvoda - Greška'
          this.message = 'Izvod NIJE učitan.' + error
          this.dcolor = 'red'
          this.showDialog = true
        })
      } catch (error) {
        this.locError = this.lang.alertError4
        this.text = 'Error ' + error
        this.color = 'red'
        this.snackbar = true
        alert(this.locError)
      }
    },
    async openStatement (type, ps) {
      // try {
        this.showCard1 = false
        this.showCard2 = false
        this.showCard3 = false
        this.showCard4 = false
        this.showCard5 = false
        this.showCard6 = false
        this.showCard7 = false
        this.showCard10 = false
        this.fTotal1 = 0
        this.fTotal2 = 0
        this.fTotal3 = 0
        this.fTotal4 = 0
        this.fTotal5 = 0
        this.fTotal6 = 0
        const newObj = {}
        this.mainledgerAna = {...newObj}

        this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainledgerAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
        this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
        console.log('0 this.mainledgerAna', this.mainledgerAna)
        this.head = {}
        if (type === 1) {
          this.mainledgerAna.MLType = 1
          this.typeCard = 1
        }
        if (type === 2) {
          this.mainledgerAna.MLType = 2
          this.typeCard = 2
        }
        if (this.startDate) {
          this.mainledgerAna.docFromDate = dayjs(this.startDate).format('YYYY-MM-DD')
        }
        if (this.endDate) {
          this.mainledgerAna.docToDate = dayjs(this.endDate).format('YYYY-MM-DD')
        }
        this.head = {...this.mainledgerAna}
        this.pItems.splice(0,this.pItems.length)
        this.payments.splice(0,this.payments.length)
        this.tItems.splice(0,this.tItems.length)
        this.$store.dispatch('setSearchState', this.mainledgerAna)
        this.saving = true

        const find1 = {}
        find1.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        find1.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
        find1.MLType = type === 1 ? 1 : 2


        console.log('1 find1', find1)
        const res1= await MainLedgerService.sumbyvat(find1)
        const allInv = res1.data && res1.data.documents ? res1.data.documents : []
        console.log('1 allInv', allInv.length)
        const revi = allInv.find(it =>  it.partnerVATID === '76879095834')
        console.log('revitana ', revi)
        const find2 = {...find1}
        find2.notDue = true
        console.log('2 find2', find2)
        const res2 = await MainLedgerService.sumbyvat(find2)
        const notDueInv = res2.data && res2.data.documents ? res2.data.documents : []
        console.log('2 notDueInv', notDueInv.length)

        const find3 = {...find1}
        find3.openStatement = true
        console.log('3 this.find3', find3)
        const res3 = await MainLedgerService.sumbyvat(find3)
        const dueInv = res3.data && res3.data.documents ? res3.data.documents : []
        console.log('3 dueInv', dueInv.length)

        const res4 = await MainBookCompService.sumbyvat(this.mainledgerAna)
        this.payments = res4.data && res4.data.documents ? res4.data.documents : []
        //console.log('31 dueInv', this.payments)

        // await MainLedgerService.sumbyvat(this.mainledgerAna)
        // .then(async(res) => {
        //   this.pItems = res.data.documents ? res.data.documents : []
        //   await MainBookCompService.sumbyvat(this.mainledgerAna)
        //   .then(res2 => {
        //     this.payments = res2.data.documents ? res2.data.documents : []
        //   })
        //   .catch(err2 => {
        //     this.error = 'Greska u konekciji na bazu' + err2
        //     this.stateBooks = false
        //   })

         //this.showCard5 = true
          // console.log('this.pItems', this.pItems)
          // console.log('this.payments', this.payments)

         if (allInv) {
          allInv.map(pit => {
            if (pit.partnerVATID && pit.partnerVATID.trim().length > 0 ) {

              const nDueInv = pit.partnerVATID ? notDueInv.find(payit => payit.partnerVATID === pit.partnerVATID) : null
              const dueInvo = pit.partnerVATID ? dueInv.find(payit => payit.partnerVATID === pit.partnerVATID) : null
              const havePayment = pit.partnerVATID ? this.payments.find(payit => payit.partnerVATID === pit.partnerVATID) : null

              const allInv = pit.totalInvoice ? round(pit.totalInvoice ,2) : 0
              
              const allDue = dueInvo && dueInvo.totalInvoice ? round((parseNum(dueInvo.totalInvoice) / this.exRate) ,2) : 0
              const allNotDue = nDueInv && nDueInv.totalInvoice ? round((parseNum(nDueInv.totalInvoice) / this.exRate) ,2) : 0
              const allPay = havePayment && havePayment.totalPayment ? round((Number(havePayment.totalPayment) / this.exRate) ,2) : 0

              const havePartner = this.$store.state.partnerStore.find(partner => partner.partnerVATID === pit.partnerVATID)
              let saldo = 0
              if (type === 1) {
              saldo = round((allPay - allDue),2)
              }
              if (type === 2) {
              saldo = round((allDue - allPay),2)
              }
              if (pit.partnerVATID === '76879095834'){
                console.log('allInv revitana', allInv, allDue, allPay, saldo, allNotDue)}

           //   if (round((saldo + allNotDue),2) !== 0) {

              if (round((allInv),2) !== 0) {
                
                const newItem = {}
                newItem.partner = havePartner ? havePartner : {}
                newItem.partnerVATID = pit.partnerVATID
                newItem.allInv = allInv
                newItem.allDue = allDue
                newItem.allPay = allPay
                newItem.saldo = saldo
                newItem.allNotDue = allNotDue
                newItem.allOpen = round((saldo + allNotDue),2)
                newItem.saldo = saldo
                this.tItems.push(newItem)
                this.fTotal1 += allInv
                this.fTotal2 += allDue
                this.fTotal3 += allPay
                this.fTotal4 += saldo
                this.fTotal5 += allNotDue
                this.fTotal6 += round((saldo + allNotDue),2)
              }
           }
          })
          //console.log('this.tItems', this.tItems)

          // add payments without partner invoices -
          this.payments.map(havePayment => {
            const allInv =  0
            const allDue =  0
            const allNotDue =  0
            const allPay = havePayment.totalPayment ? round((parseNum(havePayment.totalPayment)  / this.exRate) ,2) : 0

            let saldo = 0
            if (type === 1) {
              saldo = round((allPay - allInv),2)
            }
              if (type === 2) {
              saldo = round((allInv - allPay),2)
            }
            let wNew = false
            if (!havePayment.partnerVATID && round((parseNum(havePayment.totalPayment)  / this.exRate) ,2) !==0) {
              wNew = true
            }
            if (havePayment.partnerVATID && havePayment.partnerVATID.trim().length === 0 && round((parseNum(havePayment.totalPayment)  / this.exRate) ,2) !==0 && wNew === false) {
              wNew = true
            }
            if (havePayment.partnerVATID && havePayment.partnerVATID.trim().length > 0 && round((parseNum(havePayment.totalPayment)  / this.exRate) ,2) !==0 && wNew === false) {
              const haveInList = this.tItems.find(payit => payit.partnerVATID === havePayment.partnerVATID)
              if (typeof haveInList === 'undefined') {
                const havePartner = this.$store.state.partnerStore.find(partner => partner.partnerVATID === havePayment.partnerVATID )
                if (saldo !== 0) {
                  const newItem = {}
                  newItem.partner = havePartner ? havePartner : {}
                  newItem.partnerVATID = havePayment.partnerVATID
                  newItem.allInv = allInv
                  newItem.allDue = allDue
                  newItem.allPay = allPay
                  newItem.saldo = saldo
                  newItem.allNotDue = allNotDue
                  newItem.allOpen = round((saldo + allNotDue),2)
                  this.tItems.push(newItem)
                  this.fTotal1 += 0
                  this.fTotal2 += 0
                  this.fTotal3 += allPay
                  this.fTotal4 += saldo
                  this.fTotal5 += 0
                  this.fTotal6 += round((saldo),2)

                }
              }
            }
            if (wNew) {
              const newItem = {}
                  newItem.partner = null
                  newItem.partnerVATID = null
                  newItem.allInv = allInv
                  newItem.allDue = allDue
                  newItem.allPay = allPay
                  newItem.saldo = saldo
                  newItem.allNotDue = allNotDue
                  newItem.allOpen = round((saldo + allNotDue),2)
                  this.tItems.push(newItem)
                  this.fTotal1 += 0
                  this.fTotal2 += 0
                  this.fTotal3 += allPay
                  this.fTotal4 += saldo
                  this.fTotal5 += 0
                  this.fTotal6 += round((saldo),2)
            }
          })
          this.tItems.sort(dynamicSort("-allOpen"))
          let showCard = true
          if (ps && ps ==='PS') {
            showCard = false
          }
          this.showCard10 = showCard
         }

        // }
        // .catch(err => {
        //    console.log('Error ', err)
        //    this.error = 'Error  ' + err
        // })
        this.saving = false
      //}
      // catch (error) {
      //   this.error = 'Greska u konekciji na bazu' + error
      //   this.stateBooks = false
      // }
    },
    setAllFalse() {
      this.showCard1 = false
      this.showCard2 = false
      this.showCard3 = false
      this.showCard4 = false
      this.showCard5 = false
      this.showCard6 = false
      this.showCard7 = false
      this.showCard10 = false
      this.showCard11 = false
      this.showCard12 = false
  }

  },
  watch: {
    'showEUR' () {
      if (!this.showEUR || this.showEUR) {
        this.setAllFalse()
      }

      this.defCurrency = this.showEUR ? this.$store.state.newCurrency : this.$store.state.oldCurrency
      this.exRate = this.showEUR ? this.$store.state.defExRate : 1

    },
    'selectPa' () {
      if (!this.selectPa) {
        this.setAllFalse()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
