<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2>
          {{lang.EmployeeCreate}}
        </h2>
      </v-col>
    </v-row>
    <v-row>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <h3 class="text-xs-left">
              {{lang.BasicData}}  
            </h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <v-text-field
              :label="lang.hashtag"
              required
              :rules="[required]"
              v-model="employee.hashtag"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-checkbox
              :label="lang.Activ"
              v-model="employee.Activ"
            ></v-checkbox>
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-checkbox
              :label="lang.Black"
              v-model="employee.Black"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.First"
              required
              :rules="[required]"
              v-model="employee.First"
            ></v-text-field> 
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.Last"
              required
              :rules="[required]"
              v-model="employee.Last"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
             <v-text-field
                label="email *:"
                required
                :rules="[required]"
                v-model="employee.eemail"
              ></v-text-field>
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <v-text-field
                :rules="[required]"
                label="Telefon *"
                v-model="employee.phone"
              ></v-text-field>
          </v-col>
        </v-row> 

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
          <v-text-field
              :label="lang.VatID"
              required
              :rules="[required]"
              v-model="employee.VatID"
            ></v-text-field>            
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
             <v-text-field
              :label="lang.Gender"
              v-model="employee.Gender"
            ></v-text-field> 
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.PersonalID1"
              v-model="employee.PersonalID1"
            ></v-text-field> 
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.PersonalID2"
              v-model="employee.PersonalID2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            {{lang.DateOfBirht}} <input type="date"  v-model="employee.DateOfBirht" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
                :label="lang.PlaceOfBirht"
                v-model="employee.PlaceOfBirht"
              ></v-text-field> 
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.PlaceOfBirthState"
              v-model="employee.PlaceOfBirthState"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.Foriner"
              v-model="employee.Foriner"
            ></v-text-field> 
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.ForinerLicense"
              v-model="employee.ForinerLicense"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-btn
              dark
              class="blue mr-2"
              :disabled="!formIsValid"
              @click="register">
              {{langC.Save}}
            </v-btn>
          </v-col>
           <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-btn
              color="green"
              @click="employeeList">
              {{langC.Back}}
            </v-btn>
          </v-col>
        </v-row>

      </v-col>


      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">
        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
             <h3 class="text-xs-left">
                {{lang.AdditionalData}}
              </h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            {{lang.StartInCompany}} <input type="date"  v-model="employee.StartInCompany" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8">
            <v-select
              :items="vrstaRadnogOdnosa"
              v-model="selectVrstaRadnogOdnosa"
              label="Vrsta Radnog Odnosa *"
              single-line
              item-text="text"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select> 
          </v-col>
        </v-row>
        <v-row dense class="mt-0 pt-0">
          <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8">
            <v-select
              :items="vrstaRadnogVremena"
              v-model="selectVrstaRadnogVremena"
              label="Vrsta Radnog Vremena *"
              single-line
              item-text="text"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
              class="mt-2"
            ></v-select> 
          </v-col>
 
          <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
            <v-text-field
              :label="lang.WeeklyWorkHours"
              v-model="employee.WeeklyWorkHours"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row dense>
          <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8">
            <v-select
              :items="obrazovanje"
              v-model="selectObrazovanje"
              label="Obrazovanje *"
              single-line
              item-text="text"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
              class="mt-2"
            ></v-select> 
          </v-col>
 
          <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
            <v-text-field
              :label="lang.Degree"
              v-model="employee.Degree"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
             <v-text-field
              :label="lang.Occupation"
              v-model="employee.Occupation"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.SpecialKnowledge"
              v-model="employee.SpecialKnowledge"
            ></v-text-field>
          </v-col>
        </v-row> 

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkPlace"
              v-model="employee.WorkPlace"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              label="Staž sa povećanim trajanjem Joppd 7.1 (oznaka 0 ili 1)"
              v-model="employee.Joppd71Oznaka"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row> 

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkForOtherCompany"
              v-model="employee.WorkForOtherCompany"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkForOtherCompanyAbroad"
              v-model="employee.WorkForOtherCompanyAbroad"
            ></v-text-field>
          </v-col>
        </v-row> 


        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkForOtherConnectedCompany"
              v-model="employee.WorkForOtherConnectedCompany"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.WorkHardConditions"
              v-model="employee.WorkHardConditions"
            ></v-text-field>
          </v-col>
        </v-row> 

        <v-row dense>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            <v-text-field
              :label="lang.NotWorkingTime"
              v-model="employee.NotWorkingTime"
            ></v-text-field>
          </v-col>
        </v-row> 
        <v-row dense>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
             <v-text-field
                :label="lang.PlaceOfLive"
                v-model="employee.PlaceOfLive"
              ></v-text-field>
          </v-col>
        </v-row>

          
      </v-col>


      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <h3 class="text-xs-left">
              {{lang.InternalNotes}}        
            </h3>
          </v-col>
        </v-row> 

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <v-textarea
              :label="lang.OtherData"
              v-model="employee.OtherData"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            {{lang.EndDateInCompany}} <input type="date"  v-model="employee.EndDateInCompany" />
          </v-col>
        </v-row> 

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <v-text-field
              :label="lang.EndReason"
              v-model="employee.EndReason"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import EmployeeService from '@/services/EmployeeService'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'employeecreate',
  data () {
    return {
      lang: {},
      langC: {},
      menu: false,
      menu2: false,
      menu3: false,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      msg: '',
      obrazovanje: [],
      selectObrazovanje: {},
      vrstaRadnogOdnosa: [],
      selectVrstaRadnogOdnosa: {},
      vrstaRadnogVremena: [],
      selectVrstaRadnogVremena: {},
      newEmployee: {},
      employee: {},
      required: (value) => !!value || 'Required.',
      error: ''
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
    this.obrazovanje = this.$store.state.obrazovanje
    this.vrstaRadnogOdnosa = this.$store.state.vrstaRadnogOdnosa
    this.vrstaRadnogVremena = this.$store.state.vrstaRadnogVremena
    if (this.$store.state.editItem === 1) {
      if (!this.$store.state.employeeid) {
        this.employeeList()
        return
      }
      await EmployeeService.show(this.$store.state.employeeid)
      .then(res => {   
        this.employee = res.data.employee
        this.selectObrazovanje = this.employee.Obrazovanje ? this.obrazovanje.find(obr => obr.value === parseInt(this.employee.Obrazovanje)) : null
        this.selectVrstaRadnogOdnosa = this.employee.VrstaRadnogOdnosa ? this.vrstaRadnogOdnosa.find( vrO => vrO.value === parseInt(this.employee.VrstaRadnogOdnosa)) : null
        this.selectVrstaRadnogVremena = this.employee.VrstaRadnogVremena ? this.vrstaRadnogVremena.find( vrV => vrV.value === parseInt(this.employee.VrstaRadnogVremena)) : null
        }
      )
      .catch(err => {
        console.log('err', err)
      })
    }
  },
  methods: {
    async register () {
      try {
        if (this.$store.state.user.id) {
          this.employee.UserId = this.$store.state.user.id
        }
        if (this.$store.state.user.email) {
          this.employee.email = this.$store.state.user.email
          this.employee.LastUpdateBy = this.$store.state.user.email
        }
        if (this.$store.state.companyid) {
          this.employee.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.companyName) {
          this.employee.CompanyNameDatabase = this.$store.state.companyName
        }
        if (this.$store.state.busniessYear) {
          this.employee.BusinessYear = this.$store.state.busniessYear
        }
        if (this.$store.state.editItem === 0) {
          await EmployeeService.post(this.employee)
          this.$router.push({
            name: 'employee'
          })
        }
        if (this.$store.state.editItem === 1) {
          await EmployeeService.put(this.employee)
          this.$router.push({
            name: 'employeeview'
          })
        }
        
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    async employeeList () {
      try {
        await this.$router.push({
          name: 'employeeview'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    doFlap1 () {
      if (this.employee.Activ === true) {
        this.employee.Black = false
      }
    },
    doFlap2 () {
      if (this.employee.Black === true) {
        this.employee.Activ = false
      }
    }
  },
  computed: {
    formIsValid () {
      return this.employee.eemail !== '' &&
        this.employee.hashtag !== '' &&
        this.employee.First !== '' &&
        this.employee.Last !== '' &&
        this.employee.VatID !== ''
    }
  },
  watch: {
    menu (val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    'employee.Activ' () {
      this.doFlap1()
    },
    'employee.Black' () {
      this.doFlap2()
    },
    'selectObrazovanje' () {
      this.employee.Obrazovanje = this.selectObrazovanje ? this.selectObrazovanje.value : null
    },
    'selectVrstaRadnogOdnosa' () {
      this.employee.VrstaRadnogOdnosa = this.selectVrstaRadnogOdnosa ? this.selectVrstaRadnogOdnosa.value : null
    },
    'selectVrstaRadnogVremena' () {
      this.employee.VrstaRadnogVremena = this.selectVrstaRadnogVremena ? this.selectVrstaRadnogVremena.value : null
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
