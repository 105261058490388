import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index(search) {
    return Api().get('document', {
      params: search
    })
  },
  indexlist(search) {
    return Api().get('document/list', {
      params: search
    })
  },
  docsall(query) {
    return Api().get('document/docsall', {
      params: query
    })
  },
  show(documentid) {
    return Api().get(`document/${documentid}`)
  },
  post(newdocument) {
    return Api().post('document', newdocument)
  },
  put(document) {
    return Api().put(`document/${document.id}`, document)
  },
  delete(documentid) {
    return Api().delete(`document/${documentid}`)
  },
  copy(query) {
    return Api().get(`document/copy`, {
      params: query
    })
  },
  choose(query) {
    return Api().get('document/choose', {
      params: query
    })
  },
  makesum(query) {
    return Api().get('document/makesum', {
      params: query
    })
  },
  sumby(query) {
    return Api().get('document/sumby', {
      params: query
    })
  },
  copydocdet(query) {
    return Api().get('document/copydocdet', {
      params: query
    })
  },
  documentpurchaseana(query) {
    return Api().get('document/purchaseana', {
      params: query
    })
  },
  documentsalesana(query) {
    return Api().get('document/salesana', {
      params: query
    })
  },
  documentfiskalcheck(query) {
    return Api().get('document/documentfiskalcheck', {
      params: query
    })
  },
  documentitemcard(query) {
    return Api().get('document/itemcard', {
      params: query
    })
  },
  documentitemcardsales(query) {
    return Api().get('document/itemcardsales', {
      params: query
    })
  },
  findallbyuuid(query) {
    console.log('service query', query)
    return Api().get('/document/findallbyuuid', {
      params: query
    })
  },
  documentwarehousestate(query) {
    return Api().get('document/warehousestate', {
      params: query
    })
  },
  documentfiskalsave(document) {
    return Api().post('document/fiskalsave', document)
  },
  documentfiskalread(query) {
    return Api().get('document/fiskalread', {
      params: query
    })
  },
  documentfiskalprint(document) {
    return Api().post('document/fiskalprint', document)
  },
  updatedocdoc(yeartofill) {
    return Api().get('document/updatedocdoc', {params: yeartofill})
  },
  documentsavefile(formData) {
    return Api().post('document/savefile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  saveinvoices(items) {
    if (items) {
      return Api().post('document/saveinvoices', items)
    }
    return
  },
}